package driver.menu

import app.ApiDriver
import domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class MenuP(router:eRouter<HTMLElement>): PageBase(::Menu, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        ApiDriver.menu()?.also{ view?.entity(it) }
    }
}