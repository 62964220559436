package boundxDriver.common.enm

// 배송상태탭
enum class EnumDeliveryStateTab(val rowid:Int, val title:String, val dispatchTitle:String, val className:String) {
    ALL(0, "전체", "전체", ""),
    NO_PACKAGING(10, "미포장", "미포장","state-lightgray"),
    SHIPPING(30, "배송중", "배송중","state-blue"),
    COMPLETE(50, "배송완료", "배송완료","state-green"),
    CANCEL(70, "배송취소", "배송취소","state-red");
    companion object{
        operator fun invoke(i:Int):EnumDeliveryStateTab = values().find{it.rowid == i}?: error("없는 배송상태입니다.")
        operator fun invoke(i:String):EnumDeliveryStateTab
        = values().find{it.rowid.toString() == i}?: error("없는 배송상태입니다.")
    }
}