package boundxDriver.common.vali

import ein2b.core.validation.eRuleVali

val ValiDriverPhone = eRuleVali{
    Case{
        String()
        Trim()
        Rule{ "$it".replace("-","").replace(" ", "") }
        MinLength(1, "휴대폰번호를 입력해 주세요.")
        MaxLength(16, "휴대폰번호를 잘못 입력했습니다.")
        Rule("휴대폰번호를 잘못 입력했습니다."){ _valiPhoneCheck(it) }
    }
}

val ValiAppDriverPhone = eRuleVali{
    Case{
        String()
        Trim()
        Rule{ "$it".replace("-","").replace(" ", "") }
        MinLength(1, "휴대폰번호를 입력해 주세요.")
        MaxLength(16, "휴대폰번호를 잘못 입력했습니다.")
        Rule("휴대폰번호를 잘못 입력했습니다."){ _valiPhoneCheck(it) }
    }
}
val ValiDriverAuthCode = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "인증번호를 입력해 주세요.")
        MaxLength(6, "인증번호를 6자 이내로 입력해 주세요.")
        Rule("인증번호를 6자 이내로 숫자만 입력해 주세요."){ _valiUintCheck(it) }
    }
}
val ValiDriverName = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "이름은 32자 이내로 입력해 주세요.")
        MaxLength(32, "이름은 32자 이내로 입력해 주세요.")
    }
}

val ValiDriverImageUrl = eRuleVali{
    Case{
        String()
        Trim()
        Rule("이미지를 불러올 수 없습니다."){ _valiImageUrlCheck(it) }
    }
}


val ValiDriverGroupR = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("Driver Group R은 정수입니다."){
            _valiUintCheck(it)
        }
    }
}

val ValiDriverR = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("Driver R은 정수입니다."){
            _valiUintCheck(it)
        }
    }
}
val ValiDriverGroupTitle = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "기사 그룹 이름은 32자 이내로 입력해 주세요.")
        MaxLength(32, "기사 그룹 이름은 32자 이내로 입력해 주세요.")
    }
}