package boundxDriver.common.entity.api.user

import boundxDriver.common.enm.EnumApiAccessAllow
import boundxDriver.common.enm.db.EnumAuthorityCat
import boundxDriver.common.enm.db.EnumDeliveryState
import boundxDriver.common.vali.ValiPage
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum

//[서브전용]배송사 관련 API 엔티티

const val SUB_SHIPPER_API_PATH = "/user/api/sub/shipper"

//--- 배송의뢰사 홈 -----------------------------------------------------------------
class EntUserApiSubShipperHomeReq: EntUserApiReq(){
    companion object {
        const val PATH = "$SUB_SHIPPER_API_PATH/home/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean): String  =
        (if(isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "1"))
    override fun _accessAllow() =  EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(
        EnumAuthorityCat.SHIPPER_DELIVERY_LIST,
        EnumAuthorityCat.SHIPPER_DELIVERY_REQUEST
    )
    var userId = ""

}
class EntUserApiSubShipperHomeRes: eEntity(true) {
    var name:String by string(::name)
    var userId:String by string(::userId)
    var phone:String by string(::phone)
    var profileUrl:String by string(::profileUrl)
    var requestAllowed: Boolean by bool(::requestAllowed)
    var listAllowed: Boolean by bool(::listAllowed)
}
//--- 배송리스트 -----------------------------------------------------------------
class EntUserApiSubShipperDeliveryReq:EntUserApiReq(){
    companion object {
        const val PATH = "$SUB_SHIPPER_API_PATH/delivery/{userId}/{page}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean): String  =
        if(isJSON) PATH_JSON.replace("{userId}", "1").replace("{page}", page)
        else PATH.replace("{userId}", "1").replace("{page}", page)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_LIST)
    var userId = ""
    var page:String by string(::page) {
        default("1")
        validator(ValiPage)
    }
}
class EntUserApiSubShipperDeliveryRes:eEntity(true){
    class Meta: EntUserApiPageMeta()
    class Delivery:eEntity(true){
        var deliveryRowid:String by string(::deliveryRowid)
        var deliveryTitle:String by string(::deliveryTitle) //배송 #2111260002
        var deliveryState:EnumDeliveryState by enum(::deliveryState)
        var itemTitle:String by string(::itemTitle) // 코미나티주(토지나메란)
        var requestDate:String by string(::requestDate) // 배송 출발 요청일 2021-11-27(수) 09:00
        var packerAddr:String by string(::packerAddr) // 포장사 주소
        var packerName:String by string(::packerName) // 포장사 모사이포장
        var receiverAddr:String by string(::receiverAddr) // 수령사 주소
        var receiverName:String by string(::receiverName) // 수령사 이름
    }
    val meta: Meta by entity(::meta, ::Meta){ default(Meta(), false) }
    val deliveryList: MutableList<Delivery> by entityList(::deliveryList, ::Delivery){ default(mutableListOf(), false) }
}
