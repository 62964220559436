package shipper.deliveryList

import app.ApiShipper
import domPage.PageBase
import boundxDriver.common.entity.api.user.EntUserApiSubShipperDeliveryReq
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class DeliveryListP(router:eRouter<HTMLElement>): PageBase(::DeliveryListView, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        ApiShipper.subShipperDeliveryList(EntUserApiSubShipperDeliveryReq().also{
            it.userId = ApiShipper.userId
        })?.also {
            view?.entity(it)
        }
    }
}