package entity

import app.ApiDriver
import ein2b.core.entity.eEntity
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiDriverERes

class EntDriverProfile:eEntity(){
    companion object{
        operator fun invoke(res:EntAppDriverWebApiDriverERes) = EntDriverProfile().also{
            it.profileUrl = ApiDriver.member.profileUrl
            it.profileUrlList = res.profileUrlList.map{ url->
                Profile().also{ profile->
                    profile.img = url
                    profile.selected = it.profileUrl == url
                }
            }.toMutableList()
        }
    }
    class Profile:eEntity(){
        var img:String by string(::img)
        var selected: Boolean by bool(::selected)
    }
    var profileUrl:String by string(::profileUrl)
    var profileUrlList: MutableList<Profile> by entityList(::profileUrlList, ::Profile)
}