package comp


import ein2b.core.view.click
import ein2b.core.view.eView
import ein2b.core.view.html
import org.w3c.dom.HTMLElement

class CompWebAppHeader{
    companion object{
        // language=html
        private val factory = Factory.html("""
<div class="m-header">
    <div data-view="btn" class="btn-back"></div>
    <div data-view="title" class="title"></div>
</div>""")
        suspend operator fun invoke(root:eView<HTMLElement>, block:(CompWebAppHeader)->Unit = {}): CompWebAppHeader {
            val comp = CompWebAppHeader()
            block(comp)
            comp.target = root.sub("header", factory){
                it.sub("title").html = comp.title
                it.sub("btn") { btn ->
                    //btn.className = if(comp.isBack) "dsad" else "dasdsa"
                    comp.backClick?.also {
                        btn.click = {_,_ -> it() }
                    }
                }
            }
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    var backClick:(()->Unit)? = null
    var title = ""
//    var isBack = false

    suspend fun setTitle(title:String){
        target.sub("title").html = title
    }
}