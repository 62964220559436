package shipper.home

import Factory
import app.ApiShipper
import app.DriverApp
import app.hashManager
import app.App
import boundxDriver.common.entity.api.user.EntUserApiMemberLoginReq
import boundxDriver.common.entity.api.user.EntUserApiMemberLogoutReq
import boundxDriver.common.entity.api.user.EntUserApiSubShipperHomeReq
import boundxDriver.common.entity.api.user.EntUserApiSubShipperHomeRes
import boundxDriver.common.vali.ValiMemberPw
import boundxDriver.driver.RouterKeyAppDriverWeb.SUB_SHIPPER_DELIVERY_LIST
import boundxDriver.driver.RouterKeyAppDriverWeb.SUB_SHIPPER_REQUEST
import comp.CompWebAppHeader
import compLabelInputSectionPasswordSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.crypto.eCrypto
import entity.EntInit
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("shipper/home")
private enum class K{
    login, LABEL, LABEL1, INFO, userName, userId, phone, logoutBtn, requestBtn, listBtn, requestAccess, listAccess, profile,
    id, pass, btn;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun HomeView() = eView(factory){ rootView->
    rootView.sub(K.login){ loginView->
        CompWebAppHeader(loginView){
            it.title = "c@배송의뢰사 기능@driver_app/title/client"
            it.backClick = { DriverApp.back() as Unit }
        }
        val vali = EntUserApiMemberLoginReq()
        val id = loginView.compLabelInputSectionTextSet(K.id, "c@아이디@driver_app/label/userid", vali.getVali(vali::userId))
        val pw = loginView.compLabelInputSectionPasswordSet(K.pass, "c@비밀번호@driver_app/label/pw", ValiMemberPw)
        loginView.sub(K.btn){
            it.html = "c@로그인@driver_app/button/login"
            it.click = { _, _->
                eLaunch{
                    if(App.checkAll(id, pw)){
                        ApiShipper.rsa()?.also{ rsa ->
                            ApiShipper.login(EntUserApiMemberLoginReq().also{ req ->
                                req.rsaId = rsa.rsa.id
                                req.userId = id.out()
                                req.pw = eCrypto.rsaEncrypt(pw.out(), rsa.rsa.key)
                            })?.also{ res ->
                                if(res.isError){
                                    id.changeError(res.error.userId)
                                    pw.changeError(res.error.pw)
                                }else rootView.entity(EntInit)
                            }
                        }
                    }
                }
            }
        }
        loginView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
            override suspend fun invoke(view:eView<HTMLElement>, entity:EntInit){
                id.clear()
                pw.clear()
                loginView.displayBlock()
            }
        })
    }

    CompWebAppHeader(rootView){
        it.title = "c@배송의뢰사 기능@driver_app/title/client"
        it.backClick = { DriverApp.back() as Unit }
    }
    rootView.sub(K.logoutBtn){
        it.html = "c@로그 아웃@driver_app/button/logout"
        it.click = {_,_ ->
            eLaunch{
                ApiShipper.logout(EntUserApiMemberLogoutReq().also{ req ->
                    req.userId = ApiShipper.userId
                }).also{
                    rootView.sub(K.login).entity(EntInit)
                }
            }
        }
    }
    rootView.sub(K.requestBtn).click = { _, _ -> hashManager.goUrl(SUB_SHIPPER_REQUEST) }
    rootView.sub(K.listBtn).click = { _, _ -> hashManager.goUrl(SUB_SHIPPER_DELIVERY_LIST) }
    rootView.sub(K.requestAccess).html = "(권한 없음)"
    rootView.sub(K.listAccess).html = "(권한 없음)"
    App.subHtmlFromLabel(rootView,
        K.LABEL to "배송 의뢰",
        K.LABEL1 to "배송 리스트",
        K.INFO to "c@배송 의뢰/배송 리스트 조회 권한을 가진 계정으로 로그인하세요.@driver_app/text/login"
    )
    App.emptySub(rootView, K.userName, K.userId, K.phone, K.profile)
    rootView.addEntityHook(EntUserApiSubShipperHomeRes::class, object:eEntityHook<HTMLElement, EntUserApiSubShipperHomeRes>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntUserApiSubShipperHomeRes){
            view.sub(K.login).displayNone()
            view.sub(K.userName).html = entity.name
            view.sub(K.userId).html = "@${entity.userId}"
            view.sub(K.phone){
                if(entity.phone.isBlank()) it.displayNone() else it.displayBlock()
                it.html = "연락처: ${entity.phone}"
            }
            view.sub(K.profile).lazyBackgroundImage = "" to entity.profileUrl
            val requestAccess = view.sub(K.requestAccess)
            val requestBtn = view.sub(K.requestBtn)
            val listAccess = view.sub(K.listAccess)
            val listBtn = view.sub(K.listBtn)
            if(entity.requestAllowed){
                view.sub(K.requestAccess).displayNone()
                rootView.sub(K.requestBtn).className = "border-box is-active"
            }else{
                requestAccess.displayBlock()
                requestBtn.className ="border-box is-not-active"
                requestBtn.disabled = true
            }
            if(entity.listAllowed){
                listAccess.displayNone()
                listBtn.className = "border-box is-active"
            }else{
                listAccess.displayBlock()
                listBtn.className = "border-box is-not-active"
                listBtn.disabled = true
            }
        }
    })
    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntInit){
            if(ApiShipper.isLogin) ApiShipper.subShipperHome(EntUserApiSubShipperHomeReq().also{
                    it.userId = ApiShipper.userId
                })?.also{ rootView.entity(it) }
            else rootView.sub(K.login).entity(entity)
        }
    })
}