package boundxDriver.common.entity.api.user

import boundxDriver.common.enm.EnumApiAccessAllow
import boundxDriver.common.enm.db.EnumAuthorityCat
import ein2b.core.entity.eEntity

//RSA정보
class EntUserApiRsaReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/rsa"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGOUT
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
}
class EntUserApiRsaRes: eEntity(true){
    class RSA: eEntity(true){
        var id:String by string(::id)
        var key:String by string(::key)
    }
    var rsa: RSA by entity(::rsa, EntUserApiRsaRes::RSA){
        default(RSA(), false)
    }
}