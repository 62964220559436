package app

import boundxDriver.driver.RouterKeyAppDriverWeb.CERTIFICATE
import boundxDriver.driver.RouterKeyAppDriverWeb.DRIVER_EDIT
import boundxDriver.driver.RouterKeyAppDriverWeb.MENU
import boundxDriver.driver.RouterKeyAppDriverWeb.START
import boundxDriver.driver.RouterKeyAppDriverWeb.SUB_SHIPPER_DELIVERY_LIST
import boundxDriver.driver.RouterKeyAppDriverWeb.SUB_SHIPPER_HOME
import boundxDriver.driver.RouterKeyAppDriverWeb.SUB_SHIPPER_REQUEST
import comp.input.CompInputSingle
import driver.certificate.CertificateP
import driver.edit.DriverEditP
import driver.menu.MenuP
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eDomBase
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import shipper.deliveryList.DeliveryListP
import shipper.deliveryRequest.DeliveryRequestAddP
import shipper.home.ShipperHomeP
import shipper.modal.ShipperRequestAddrChangeClose
import start.StartP

val hashManager = eHashManager(START, {
    eLaunch{ ShipperRequestAddrChangeClose() }
    CompInputSingle.inputBlur()
}).apply{
    val baseEl = document.querySelector("#base") as? HTMLElement ?: err("invalid #base")
    addRouters(
        eStaticRouter(eDomBase(baseEl)).apply{
            addFactories(
                CERTIFICATE to { router -> CertificateP(router) },

                START to { router -> StartP(router) },
                MENU to { router -> MenuP(router) },
                DRIVER_EDIT to { router -> DriverEditP(router) },
                SUB_SHIPPER_HOME to { router -> ShipperHomeP(router) },
                SUB_SHIPPER_REQUEST to { router -> DeliveryRequestAddP(router) },
                SUB_SHIPPER_DELIVERY_LIST to { router -> DeliveryListP(router) }
            )
        }
    )
}