package start

import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import domPage.PageBase
import boundxDriver.driver.RouterKeyAppDriverWeb
import org.w3c.dom.HTMLElement

class StartP(router:eRouter<HTMLElement>): PageBase(::Start, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        hashManager.goUrl(RouterKeyAppDriverWeb.MENU)
    }
}