package driver.certificate

import app.ApiDriver
import app.DriverApp
import domPage.PageBase
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiCertificateReq
import boundxDriver.common.entity.api.user.EntApiRowid
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import view.CompViewToast

class CertificateP(router:eRouter<HTMLElement>): PageBase(::Certificate, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        data.parse(EntApiRowid())?.also{
            ApiDriver.certificate(EntAppDriverWebApiCertificateReq().also{ req->
                req.phone = DriverApp.phone() as String
                req.deliveryPackageRowid = it.r
            })?.also{
                view?.entity(it)
            }
        } ?: CompViewToast.open("잘못된 접근입니다.")
    }
}