package app

import boundxDriver.common.enm.db.EnumRoleCat
import boundxDriver.common.entity.api.user.*
import boundxDriver.driver.RouterKeyAppDriverWeb
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.*
import ein2b.js.browser.eLocalStorage
import view.CompViewAlert
import view.CompViewBlocking
import view.CompViewToast
import kotlin.js.Date

object ApiShipper{
    init{
        eApi.sender = FetchSender()
    }
    private fun memberInit() = EntUserApiMember.Login().also{
        it.userId = "1"
        it.userName = ""
        it.roleCat = EnumRoleCat.SHIPPER
        it.profileUrl = ""
    }
    var member = memberInit()
    var isLogin = false
    val userId get() = eLocalStorage["userId"] ?: "empty"

    private const val TIMEOFFSET_KEY = "timeoffset"
    private const val ENTITY_KEY = "entity"
    private const val MIDIGNORE_KEY = "midIgnore"

    private val apiMap = hashMapOf<String, eApi>()
    private suspend fun <RES:eEntity> net(reqEntity: EntUserApiReq, resEntity:RES, isJson:Boolean = false, vararg infoItems:Pair<String, Any>):RES?{
        CompViewBlocking.open()

        val argItems:MutableList<Pair<String, Any>> = mutableListOf(TIMEOFFSET_KEY to "${Date().getTimezoneOffset()}", MIDIGNORE_KEY to "true", ENTITY_KEY to { reqEntity })
        infoItems.forEach{ argItems += it }

        val infoUrl = "${ApiDriver.domain}${reqEntity._url(isJson)}"
        //console.log("infoUrl:$infoUrl")
        val api = apiMap[infoUrl] ?: eApi("", eApi.DEFAULT to eApiInfo{
            method = eApi.POST

            url = infoUrl
            items += argItems.map{ it.first }

            requestTask += eRequestTask.Header(TIMEOFFSET_KEY, MIDIGNORE_KEY)
            requestTask += eRequestTask.JsonFromEntity(ENTITY_KEY)

            responseTask += eResponseTask.Text
            responseTask += eResponseTask.Entity{ MainResponse{ resEntity } }
        })
        //log("======${reqEntity._url(isJson)}=========================")
        //log("req --- ${reqEntity.stringify()}")
        return api(*argItems.toTypedArray()).let{ result->
            CompViewBlocking.close()
            isLogin = false
            member = memberInit()
            if(result.isOk){
                @Suppress("UNCHECKED_CAST")
                (result.response!!.result as? MainResponse<RES>)?.let{
                    isLogin = it.isLogin
                    if(isLogin) (it.member as? EntUserApiMember.Login)?.also{ mem ->
                            member = mem
                            eLocalStorage["userId"] = mem.userId
                        }
                    else (it.member as? EntUserApiMember.Logout)?.also{ eLocalStorage.remove("userId") }

                    if(it.isError){
                        error(it.error)
                        null
                    }else{
                        //log("res --- ${it.data.stringify()}")
                        it.data
                    }
                }
            }else{
                log(result.err)
                null
            }
        }
    }
    suspend fun error(e: EntUserApiResponse.Error, block:(()->Unit)? = null) = when(e.method){
        boundxDriver.common.enm.EnumApiErrorMethod.ALERT -> {
            when(e.action) {
                boundxDriver.common.enm.EnumApiErrorAction.NONE -> CompViewAlert.open(e.message, block = block)
                boundxDriver.common.enm.EnumApiErrorAction.MOVE_TO_LOGIN -> hashManager.goUrl(RouterKeyAppDriverWeb.SUB_SHIPPER_HOME)
                else -> {}
            }
        }
        boundxDriver.common.enm.EnumApiErrorMethod.TOAST ->{
            if(block != null) block()
            CompViewToast.open(e.message)
        }
        else -> console.log(e.message)
    }

    suspend fun rsa():EntUserApiRsaRes? = net(EntUserApiRsaReq(), EntUserApiRsaRes())
    suspend fun login(req:EntUserApiMemberLoginReq):EntUserApiMemberLoginRes? = net(req, EntUserApiMemberLoginRes())
    suspend fun loginCheck():EntUserApiMemberLoginCheckRes? = net(EntUserApiMemberLoginCheckReq(), EntUserApiMemberLoginCheckRes())
    suspend fun logout(req:EntUserApiMemberLogoutReq):EntUserApiMemberLogoutRes? = net(req, EntUserApiMemberLogoutRes())
    suspend fun subShipperHome(req:EntUserApiSubShipperHomeReq):EntUserApiSubShipperHomeRes? = net(req, EntUserApiSubShipperHomeRes())
    suspend fun subShipperDeliveryList(req:EntUserApiSubShipperDeliveryReq):EntUserApiSubShipperDeliveryRes? = net(req, EntUserApiSubShipperDeliveryRes())

    //배송 의뢰(등록)
    suspend fun shipperDeliveryRequestW(req:EntUserApiShipperDeliveryRequestWReq):EntUserApiShipperDeliveryRequestWRes? = net(req, EntUserApiShipperDeliveryRequestWRes())
    suspend fun shipperDeliveryRequestWp(req:EntUserApiShipperDeliveryRequestWpReq):EntUserApiShipperDeliveryRequestWpRes? = net(req, EntUserApiShipperDeliveryRequestWpRes())
}