package view

import Factory
import ein2b.core.coroutine.eScheduler
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

object CompViewToast{
    enum class TOAST_TYPE(val className:String){
        DEFAULT("toast"), ERROR("toast error-toast"), SUCCESS("toast success-toast")
    }
    lateinit var ani:eScheduler
    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement){
        ani = eScheduler()
        ani.start(15)

        //language=html
        val f = Factory.html("""
<section id="toast" class="toast">
    <div data-view="icon" class="toast-badge"></div>
    <span data-view="msg"></span>
</section>
""")
        target = eView(f){
            it.displayNone()
            it.sub("icon")
            it.sub("msg")
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }

    suspend fun open(msg:String, iconUrl:String = "", type:TOAST_TYPE = TOAST_TYPE.DEFAULT, vararg attrList:Pair<String, String>){
        if(msg.isBlank()) return

        target.displayFlex()
        target.sub("msg"){t ->
            attrList.forEach { t.attr(it) }
            t.html = msg
        }
        target.sub("icon"){
            if(iconUrl.isBlank()) it.displayNone() else it.displayBlock()
            it.className = "toast-badge $iconUrl"
        }

        target.className = type.className
        target.opacity = 0.8
        ani.add{
            delay = 1000
            once{
                target.opacity = 1.0
                target.transitionEaseOutCirc(0.5)
            }
        } + {
            once{
                target.opacity = 0.0
            }
        } + {
            delay = 500
            once{
                target.transition = ""
                target.displayNone()
            }
        }
    }
}