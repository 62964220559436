package boundxDriver.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import boundxDriver.common.entity.api.user.EntUserApiSearchFilter
import boundxDriver.common.enm.EnumDeliverySearchType
import boundxDriver.common.enm.EnumDeliveryStateTab
import boundxDriver.common.enm.EnumItemSearchType

//배송 출발 요청일
val ValiShipperValidDate = eRuleVali {
    Case {
        Rule("날짜 형식이 틀렸습니다.") {
            _valiDateTimeCheck(it)
        }
    }
}
//배송할 의약품 - 수량
val ValiShipperItemQty = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("수량을 입력해 주세요."){
            _valiIntCheck(it)
        }
        Rule("수량을 입력해 주세요."){
            if("$it".toInt() == 0) eRuleSet.FALSE else it
        }
        Rule("수량은 9,999개까지 입력할 수 있습니다."){
            if("$it".toInt() < 0 || "$it".toInt() > 9999 ) eRuleSet.FALSE else it
        }
    }
}
val ValiShipperMemo = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MaxLength(200, "포장사 전달 사항은 200자 이내로 입력해 주세요.")
    }
}

val ValiShipperDeliveryRejectMessage = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "인수 거절 사유를 입력해 주세요.")
        MaxLength(200, "인수 거절 사유는 200자 내로 입력하세요.")
    }
}
//배송검색 필터
var ValiShipperDeliverySearchFilter = eRuleVali {
    Case {
        Rule("배송 검색 타입이 잘못되었습니다.") {
            try {
                val filters = it as List<EntUserApiSearchFilter>
                filters.forEach {
                    EnumDeliverySearchType(it.id)
                }
                it
            } catch (e: Throwable) {
                eRuleSet.FALSE
            }
        }
    }
}

//배송상태 일련번호
val ValiShipperDeliveryStateId = eRuleVali {
    Case {
        Rule("배송상태값이 숫자형이 아닙니다.") {
            _valiIntCheck(it)
        }
        Rule("배송상태가 맞지 않습니다.") {
            try {
                EnumDeliveryStateTab("$it")
                it
            } catch (e: Throwable) {
                eRuleSet.FALSE
            }
        }

    }
}
//제품검색 Type
val ValiShipperItemSearchType = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("제품검색용 Type을 잘못 입력했습니다."){
            try{
                EnumItemSearchType("$it")
                it
            }catch (e:Throwable){
                eRuleSet.FALSE
            }
        }
    }
}

//제품검색어
val ValiShipperItemSearchText = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Equal(__EMPTY__)
    }
    Case{
        Rule{"$it".trim()}
        MinLength(1, "검색어는 100자 이내로 입력해 주세요.")
        MaxLength(100, "검색어는 100자 이내로 입력해 주세요.")
    }
}

//의약품 별명
val ValiShipperItemNickName = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MaxLength(255, "별명은 255자 이내로 입력해 주세요.")
    }
}


val ValiShipperAddrAlias = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "별명을 입력해 주세요.")
        MaxLength(64, "별명이 너무 깁니다. 64자 내로 입력하세요.")
    }
}
val ValiShipperAddr = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "주소를 입력해 주세요.")
        MaxLength(128, "주소가 너무 깁니다. 128자 내로 입력하세요.")
    }
}
val ValiShipperRejectTitle = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "사유는 200자 이내로 입력해 주세요.")
        MaxLength(200, "사유는 200자 이내로 입력해 주세요.")
    }
}
val ValiShipperCode = eRuleVali {
    Case {
        Rule { "$it".trim() }
        Rule("연결 코드는 8자리 영문 또는 숫자만 입력해 주세요."){
            _valiUUIDCheck(it)
        }
        MinLength(1, "연결 코드는 8자리 영문 또는 숫자만 입력해 주세요.")
        MaxLength(8, "연결 코드는 8자리 영문 또는 숫자만 입력해 주세요.")
    }
}

val ValiShipperStandardCode = eRuleVali {
    Case {
        Rule { "$it".trim() }
        Rule("표준코드는 13자리 숫자로 입력해 주세요."){
            _valiIntCheck(it)
        }
        Length(13, "표준코드는 13자리 숫자로 입력해 주세요.")
    }
}

val ValiShipperExternalTitle = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "상호를 입력해 주세요.")
        MaxLength(32, "상호가 너무 깁니다. 32자 내로 입력하세요.")
    }
}
val ValiShipperName = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "이름을 입력해 주세요.")
        MaxLength(32, "이름이 너무 깁니다. 32자 내로 입력하세요.")
    }
}
val ValiShipperTel = eRuleVali {
    Case{
        Empty()
    }
    Case{
        Rule{"$it".trim().replace("-","").replace(" ", "")}
        Rule("연락처는 숫자만 입력하세요.") {
            _valiUintCheck(it)
        }
        MaxLength(16, "연락처가 너무 깁니다.")
    }
}
val ValiShipperComapnyTitle = eRuleVali {
    Case {
        Rule { "$it".trim() }
        MinLength(1, "회사명을 입력해 주세요.")
        MaxLength(32, "회사명이 너무 깁니다. 32자 내로 입력하세요.")
    }
}
val ValiShipperSign = eRuleVali {
    Case {
        Rule { "$it".trim() }
        Rule("판매(출하)담당자의 서명 경로가 잘못되었습니다.") {
            _valiImageUrlCheck(it)
        }
    }
}

