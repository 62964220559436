package entity

import boundxDriver.common.entity.api.user.EntUserApiShipperDeliveryRequestWRes
import comp.input.CompInputNumber
import ein2b.core.entity.eEntity

class EntClientShipperDeliveryRequestAddItemList: eEntity(){
    class Item: eEntity(){
        var itemRowid = ""
        var itemTitle = ""
        var supplierTitle = ""
        var itemCondition = ""
        var standardCode = ""
        var itemNick = ""
        var unitType = ""
        var qty:String = ""
        var view: CompInputNumber? = null
    }
    var itemList = mutableListOf<Item>()
}
class EntClientShipperDeliveryPass(target: EntUserApiShipperDeliveryRequestWRes):eEntity(){
    val packerList: MutableList<EntUserApiShipperDeliveryRequestWRes.Packer> by entityList(::packerList, EntUserApiShipperDeliveryRequestWRes::Packer){
        default(target.packerList)
    }
    val receiverList: MutableList<EntUserApiShipperDeliveryRequestWRes.Receiver> by entityList(::receiverList, EntUserApiShipperDeliveryRequestWRes::Receiver){
        default(target.receiverList)
    }
    val driverGroupList: MutableList<EntUserApiShipperDeliveryRequestWRes.DriverGroup> by entityList(::driverGroupList, EntUserApiShipperDeliveryRequestWRes::DriverGroup){
        default(target.driverGroupList)
    }
    val itemList: MutableList<EntUserApiShipperDeliveryRequestWRes.Item> by entityList(::itemList, EntUserApiShipperDeliveryRequestWRes::Item){
        default(target.itemList)
    }
}
class EntClientShipperRequestAddr(v:MutableList<EntUserApiShipperDeliveryRequestWRes.Receiver.Address>):eEntity(){
    var addrList: MutableList<EntUserApiShipperDeliveryRequestWRes.Receiver.Address> by entityList(::addrList, EntUserApiShipperDeliveryRequestWRes.Receiver::Address) { default(v) }
}