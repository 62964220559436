package boundxDriver.common.entity.api.user

import boundxDriver.common.enm.EnumApiAccessAllow
import boundxDriver.common.enm.EnumApiErrorAction
import boundxDriver.common.enm.EnumApiErrorMethod
import boundxDriver.common.enm.db.EnumAuthorityCat
import boundxDriver.common.enm.db.EnumRoleCat
import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion
import ein2b.core.entity.field.enum.enum
import ein2b.core.entity.field.enum.enumList
import ein2b.core.entity.field.enum.enumStringMap

sealed class EntUserApiMember: eEntity(true){
    companion object:eEntityUnion<EntUserApiMember>(EntUserApiMember::Login, EntUserApiMember::Logout)
    class Login: EntUserApiMember(){
        var userId:String by string(::userId)
        var userName:String by string(::userName)
        var roleCat: EnumRoleCat by enum(::roleCat)
        val authorityCatList: MutableList<EnumAuthorityCat> by enumList(::authorityCatList){
            default(mutableListOf(), false)
        }
        val companyCodes: MutableMap<EnumRoleCat, String> by enumStringMap(::companyCodes){
            default(mutableMapOf(), false)
        }
        var profileUrl:String by string(::profileUrl)
    }
    class Logout: EntUserApiMember(){
        var none:String by string(::none){
            default("", false)
        }
    }
}
abstract class EntUserApiResponse<DATA>: eEntity(true){
    companion object{
        val NO_ERROR = Error()
        val LOGOUT = EntUserApiMember.Logout()
        val NO_MENU = Menu()
    }
    class Error(message:String = "", method: EnumApiErrorMethod = EnumApiErrorMethod.ALERT, action: EnumApiErrorAction = EnumApiErrorAction.NONE): eEntity(true){
        var message:String by string(::message){default(message, false)}
        var method: EnumApiErrorMethod by enum(::method){default(method, false)}
        var action: EnumApiErrorAction by enum(::action){default(action, false)}
    }
    class Menu(cnt:Int = 0, isNew:Boolean = false): eEntity(true){
        var cnt: Int by int(::cnt){default(cnt, false)}
        var isNew: Boolean by bool(::isNew){default(isNew, false)}
    }
    var ver:String by string(::ver){default("1", false)}
    var member: EntUserApiMember by entity(::member, EntUserApiMember){
        default(LOGOUT, false)
    }
    var error: Error by entity(::error, ::Error){
        default(NO_ERROR)
    }
    var menu: Menu by entity(::menu, ::Menu){
        default(NO_MENU)
    }
    abstract var data:DATA
    val isError get() = error !== NO_ERROR
    val isLogin get() = member is EntUserApiMember.Login
    val isMenu get() = menu !== NO_MENU
}
class MainResponse<DATA: eEntity>(val factory:()->DATA): EntUserApiResponse<DATA>(){
    override var data: DATA by entity(::data, factory){
        default(factory(), false)
        include { !isError }
    }
}
abstract class EntUserApiReq:eEntity(){
    abstract fun _url(isJSON:Boolean) :String
    abstract fun _accessAllow(): EnumApiAccessAllow
    abstract fun _authorityCatSet():Set<EnumAuthorityCat>
    open fun _isMidIgnore():Boolean = false //가령, 로그아웃인 경우는 MID를 무시해야함
    open val rsckeys = ""
}

//Select Box의 UI의 경우 사용
open class EntUserApiSelectItem:eEntity(true) {
    var title:String by string(::title)
    var value:String by string(::value)
    var selected: Boolean by bool(::selected){default(false)}
    var disabled: Boolean by bool(::disabled){default(false)}
}
class EntUserApiSearchFilter:eEntity(true){
    var id:String by string(::id)
    var label:String by string(::label)
    var value:String by string(::value)
}

//숨김 처리가 있는 Select Box
class EntUserApiSelectHidedItem: EntUserApiSelectItem() {
    var isHided:Boolean by bool(::isHided){default(false)}
}

//각페이지 관리에 있는 셀렉트박스(디폴트 - 선택 안함)
class EntUserApiCompanyClass: eEntity(true){
    var companyClassRowid:String by string(::companyClassRowid)
    var roleCatRowid:String by string(::roleCatRowid)
    val roleCat get() = EnumRoleCat(roleCatRowid)
    var title:String by string(::title)
    var selected:Boolean by bool(::selected)
    var isHided:Boolean by bool(::isHided)
    var hideEnable:Boolean by bool(::hideEnable)
}

//운송사 셀렉트 박스
class EntUserApinvoiceComapny: eEntity(true){
    var invoiceCompanyRowid:String by string(::invoiceCompanyRowid)
    var title:String by string(::title)
    var selected:Boolean by bool(::selected)
    var isHided:Boolean by bool(::isHided)
    var isDefault:Boolean by bool(::isDefault)
}

//Page 형태의 Meta를 쓰는 경우에 이것을 직접 쓰거나 확장해서 사용할 것
open class EntUserApiPageMeta:eEntity(true){
    var page:String by string(::page){ default("1", false)}
    var totalRows:String by string(::totalRows){ default("1", false)}
    var pagePerGroup:String by string(::pagePerGroup){ default("10", false)}
    var rowPerPage:String by string(::rowPerPage){ default("10", false) }
}

class EntApiRowid:eEntity(){
    var r:String by string(::r)
}