package shipper.deliveryRequest

import CompLabelInputSection
import Factory
import app.ApiShipper
import app.App
import app.DriverApp
import app.hashManager
import boundxDriver.common.entity.api.user.EntUserApiShipperDeliveryRequestWRes
import boundxDriver.common.entity.api.user.EntUserApiShipperDeliveryRequestWpReq
import boundxDriver.common.vali.ValiShipperItemQty
import boundxDriver.driver.RouterKeyAppDriverWeb
import comp.CompPageTitle
import comp.CompWebAppHeader
import comp.compInputSectionOneSet
import comp.compInputSectionSet
import comp.input.*
import compLabelInputSectionSelectSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import entity.EntClientShipperDeliveryPass
import entity.EntClientShipperDeliveryRequestAddItemList
import entity.EntClientShipperRequestAddr
import entity.EntInit
import org.w3c.dom.HTMLElement
import prop.*
import shipper.modal.ShipperRequestAddrChangeOpen

private val factory = Factory.htmlUrl("shipper/deliveryRequest/deliveryRequestAdd")
private val factory_item = Factory.htmlUrl("shipper/deliveryRequest/itemT")
private val notPassFactory = Factory.htmlUrl("shipper/deliveryRequest/notPassT")
private val notPassItemFactory = Factory.htmlUrl("shipper/deliveryRequest/notPassItemT")

private enum class K{
    date, selectPacker, inputReceiver, selectDriver, inputItem, inputMemo, addrChangeLink,
    addrArea, addrView, notPass, pass, regBtn, itemList,
    npList_notPassList, npList_isPass, npList_msg1,
    itemList_standardCode, itemList_itemTitle, itemList_supplierTitle, itemList_itemCondition, itemList_itemNick,
    itemList_standard, itemList_cntError, itemList_inputCnt, itemList_unit, itemList_cBtn;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
//language=html
private fun setCompanyWithAddrView(tag: boundxDriver.common.enm.EnumCompanyTag, title:String, addr:String, companyClass:String) = """
<div class="flex-between" style="align-items:center">
    <div class="flex-shrink-0 ${tag.className}">${tag.tag}</div>
    <div class="flex-grow-1">$title</div>
    <div class="flex-shrink-0 state-round-badge state-gray">$companyClass</div>
</div>"""
//language=html
private fun setDriverView(phone:String, name:String) = """
<div class="flex-center">
    <div class="margin-left8">$name</div>
    <div>($phone)</div>
</div>
"""
//language=html
private fun setDriverGroupView(groupTitle:String, selectedData:String) = """
<div class="flex-center">
    <div>$groupTitle</div>
    <div class="margin-left15 margin-right15">|</div>
    $selectedData
</div>"""

private fun setAddr(ent:EntUserApiShipperDeliveryRequestWRes.Receiver.Address) = "주소: ${ent.alias} - ${ent.addr}${if(ent.isDefault) " [기본 주소]" else ""}"
private fun setItem(ent:EntUserApiShipperDeliveryRequestWRes.Item) = "<div>"+
    "<div>${if(ent.itemNick.isNotBlank()) "${ent.itemNick} - " else ""}${ent.itemTitle}</div>"+
    "<div class='margin-right8'>${ent.standardCode}</div>·<div class='margin-left8'>${ent.unitType}</div>"+
"</div>"
suspend fun DeliveryRequestAddView() = eView(factory){ rootView ->
    CompWebAppHeader(rootView){
        it.title = "배송 의뢰"
        it.backClick = { DriverApp.back() as Unit }
    }
    App.emptySub(rootView, K.notPass, K.pass, K.addrArea, K.addrView, K.addrChangeLink, K.regBtn, K.itemList)
    rootView.addEntityHook(EntUserApiShipperDeliveryRequestWRes.NotPass::class, object:eEntityHook<HTMLElement, EntUserApiShipperDeliveryRequestWRes.NotPass>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiShipperDeliveryRequestWRes.NotPass) {
            view.sub(K.notPass).setClearList{ areaVL ->
                areaVL += eView(notPassFactory) { areaV ->
                    areaV.sub(K.npList_notPassList).setClearList{ notPassVL ->
                        notPassVL += eView(notPassItemFactory){
                            it.sub(K.npList_isPass).className = if(entity.item) "pass" else "notPass"
                            it.sub(K.npList_msg1).html = "의약품 등록됨"
                        }
                        notPassVL += eView(notPassItemFactory){
                            it.sub(K.npList_isPass).className = if(entity.packer) "pass" else "notPass"
                            it.sub(K.npList_msg1).html = "포장사 연결됨"
                        }
                    }
                }
            }
        }
    })

    var reqEnt = EntUserApiShipperDeliveryRequestWpReq()
    var reqEntItemList = EntClientShipperDeliveryRequestAddItemList()
    rootView.compInputSectionSet<String>(K.date, inputClass = "flex margin-top4", errorClass = "form-error"){
        listOf(CompInputDate{
            it.ymdPattern = "Y-m-d"
            it.wrapperClass = "input-date width125"
        }, CompInputTime{
            it.wrapperClass = "input-time width90"
        })
    }
    rootView.compLabelInputSectionSelectSet<String>(K.selectPacker, "포장사", "포장사를 선택해 주세요.", wrapperClass = "width100per")
    CompLabelInputSection(rootView, K.inputReceiver, "수령사 (이름 또는 사업자 등록번호로 검색)",  CompInputData<String>{ comp ->
        comp.wrapperClass = "input-data width100per"
        comp.mustSelect = true
        comp.vali = eRuleVali{
            Case{
                Trim()
                Rule("수령사를 선택해 주세요."){
                    if("$it".isNotBlank()) it else eRuleSet.FALSE
                }
            }
        }
        comp.addOn = listOf(CompInputData.AddOnType.EMPTY)
        comp.emptyMsg = "검색된 수령사가 없습니다."
    })
    rootView.compInputSectionOneSet<String>(K.selectDriver, CompGroupSelect<String>{ comp ->
        comp.wrapperClass = "selectbox-border width100per"
        comp.placeholder = "선택해 주세요."
        comp.vali = comp.singleRule("배송의뢰사 소속 기사를 선택해 주세요.")
    })
    rootView.compInputSectionOneSet<String>(K.inputItem, CompInputData<String>{ comp ->
        comp.wrapperClass = "input-data width100per"
        comp.mustSelect = true
        comp.vali = eRuleVali{
            Case{
                Rule("의약품을 선택해 주세요."){
                    if(reqEntItemList.itemList.isNotEmpty()) it else eRuleSet.FALSE
                }
            }
        }
        comp.addOn = listOf(CompInputData.AddOnType.EMPTY)
        comp.emptyMsg = "검색된 의약품이 없습니다."
    })
    //rootView.sub(K.link).click = { _, _-> hashManager.goUrl(RouterKeyUser.SHIPPER_ITEM_LIST) }
    rootView.compLabelInputSectionTextSet(K.inputMemo, "포장사 전달사항 (선택 입력)", reqEnt.getVali(reqEnt::memo))
    rootView.sub(K.addrChangeLink){
        it.html = "주소 변경"
        it.displayNone()
    }

    rootView.addEntityHook(EntUserApiShipperDeliveryRequestWRes::class, object: eEntityHook<HTMLElement, EntUserApiShipperDeliveryRequestWRes> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiShipperDeliveryRequestWRes) {
            if(entity.isPass) {
                rootView.sub(K.notPass).displayNone()
                rootView.sub(K.pass).displayBlock()
                view.entity(EntClientShipperDeliveryPass(entity))
            } else {
                rootView.sub(K.notPass).displayBlock()
                rootView.sub(K.pass).displayNone()
                view.entity(entity.notPass)
            }
        }
    })
    rootView.addEntityHook(EntClientShipperDeliveryPass::class, object:eEntityHook<HTMLElement, EntClientShipperDeliveryPass> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientShipperDeliveryPass) {
            val inputDate = view.sub(K.date).compInputSectionString{
                it.out { it.values().joinToString(" ") }
            }
            val selectPacker = view.sub(K.selectPacker).compLabelInputSectionSelectString {
                it.input.setList { entity.packerList.map{ d->
                    it.item(setCompanyWithAddrView(d.companyTag, d.title, d.addr, d.companyClassTitle), d.companyRowid, false, false)
                }}
                it.input.checkBlock = { _ -> eLaunch{ it.changeError("", true) } }
            }

            val inputReceiver = view.sub(K.inputReceiver).compLabelInputSectionDataString{ comp ->
                comp.input.dataList = entity.receiverList.map{ CompInputData.InputData(it.companyRowid, "${it.title} (${it.businessNumber})", "${it.title}${it.businessNumber}") }.toMutableList()
                comp.input.dataListClick = { str ->
                    eLaunch {
                        view.sub(K.addrArea).displayBlock()
                        comp.changeError("", true)
                        entity.receiverList.find{ it.companyRowid == str }?.let{ receiver ->
                            view.sub(K.addrView).html = receiver.addrList.find{ it.isDefault }?.let{
                                reqEnt.receiverAddrRowid = it.rowid
                                setAddr(it)
                            }?:""
                            view.sub(K.addrChangeLink).also { btn ->
                                if(receiver.addrList.size > 1) btn.displayInline() else btn.displayNone()
                                btn.click = {_,_ ->
                                    if(receiver.addrList.size > 1) eLaunch { ShipperRequestAddrChangeOpen(
                                        EntClientShipperRequestAddr(receiver.addrList), view
                                    ) }
                                }
                            }
                        }
                    }
                }
                comp.input.refreshClick = { eLaunch{ view.sub(K.addrArea).displayNone() } }
            }

            val selectDriverSection = view.sub(K.selectDriver).compInputSectionString{ section ->
                section.inputView().compGroupSelectString { selectDriver ->
                    val receiverMap = mutableMapOf<String, List<CompInputMulti.Item<String>>>().also{ map ->
                        map[""] = mutableListOf(selectDriver.item("지정 안 함", "0", true, false))
                        entity.driverGroupList.forEach{ d ->
                            map[d.title] = d.driverList.map{ driver ->
                                selectDriver.item(setDriverView(driver.phone, driver.name), driver.companyDriverRowid, false, false)
                            }
                        }
                    }
                    selectDriver.setGroupList(receiverMap)
                    selectDriver.selectedText = { idx ->
                        val findData = selectDriver.itemList[idx]
                        if(findData.value == "0") findData.label
                        else entity.driverGroupList.find{ it.driverList.any{ it.companyDriverRowid == findData.value } }?.let{
                            setDriverGroupView(it.title, findData.label)
                        }?: ""
                    }
                }
            }

            val inputItemSection = view.sub(K.inputItem).compInputSection{ section ->
                section.inputView().compInputDataString{ itemInputData ->
                    itemInputData.dataList = entity.itemList.map{ CompInputData.InputData(it.itemRowid, setItem(it), "${it.itemNick}${it.itemTitle}${it.standardCode}") }.toMutableList()
                    itemInputData.dataListClick = { str ->
                        eLaunch{
                            itemInputData.clear()
                            entity.itemList.find{ it.itemRowid == str }?.also{ d->
                                reqEntItemList.itemList += EntClientShipperDeliveryRequestAddItemList.Item().also{
                                    it.itemRowid = d.itemRowid
                                    it.itemTitle = d.itemTitle
                                    it.supplierTitle = d.supplierTitle
                                    it.itemCondition = "${d.itemCondition} (${d.itemConditionMinTemp} ~ ${d.itemConditionMaxTemp})"
                                    it.standardCode = d.standardCode
                                    it.itemNick = d.itemNick
                                    it.unitType = d.unitType
                                }
                                itemInputData.removeData(str)
                                view.entity(reqEntItemList)
                            }
                            section.clear()
                        }
                    }
                }
            }

            val inputMemo = view.sub(K.inputMemo).compLabelInputSectionText()

            view.sub(K.regBtn){ btn ->
                btn.html = "배송 의뢰"
                btn.click = {_,_ ->
                    eLaunch {
                        reqEntItemList.itemList.forEach{ d-> d.view?.value?.check() }
                        val result = mutableListOf<Boolean>()
                        result.add(App.checkAll(selectDriverSection, inputItemSection.inputView().compInputDataString(), selectPacker, inputReceiver, inputMemo, inputDate))
                        result.add(reqEntItemList.itemList.all{ d-> d.view?.value?.isOk == true })
                        if(result.all { it }){
                            eLaunch{
                                reqEnt.userId = App.userId
                                reqEnt.requestDate = inputDate.out()
                                reqEnt.packerCompanyRowid = selectPacker.out()
                                reqEnt.receiverCompanyRowid = inputReceiver.out()
                                reqEnt.companyDriverRowid = selectDriverSection.out()
                                reqEnt.itemList.clear()
                                reqEntItemList.itemList.filter{ it.view != null }.forEach{ d->
                                    reqEnt.itemList += EntUserApiShipperDeliveryRequestWpReq.Item().also{
                                        it.itemRowid = d.itemRowid
                                        it.qty = d.qty
                                    }
                                }
                                reqEnt.memo = inputMemo.out()
                                ApiShipper.shipperDeliveryRequestWp(reqEnt)?.also{ hashManager.goUrl(RouterKeyAppDriverWeb.SUB_SHIPPER_DELIVERY_LIST) }
                            }
                        }
                    }
                }
            }
        }
    })

    rootView.addEntityHook(EntUserApiShipperDeliveryRequestWRes.Receiver.Address::class, object:
        eEntityHook<HTMLElement, EntUserApiShipperDeliveryRequestWRes.Receiver.Address> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiShipperDeliveryRequestWRes.Receiver.Address) {
            reqEnt.receiverAddrRowid = entity.rowid
            view.sub(K.addrView).html = setAddr(entity)
        }
    })
    rootView.addEntityHook(EntClientShipperDeliveryRequestAddItemList::class, object:
        eEntityHook<HTMLElement, EntClientShipperDeliveryRequestAddItemList> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientShipperDeliveryRequestAddItemList){
            view.sub(K.inputItem).compInputSection{ section ->
                val itemInputData = section.inputView().compInputDataString()
                section.clear()
                view.sub(K.itemList).setClearList{ itemList->
                    entity.itemList.forEachIndexed { idx, d->
                        itemList += eView(factory_item){ itemView->
                            itemView.sub(K.itemList_standardCode).html = d.standardCode
                            itemView.sub(K.itemList_itemTitle).html = d.itemTitle
                            itemView.sub(K.itemList_supplierTitle).html = d.supplierTitle
                            itemView.sub(K.itemList_itemCondition).html = d.itemCondition
                            itemView.sub(K.itemList_itemNick).html = d.itemNick
                            itemView.sub(K.itemList_standard).html = d.unitType
                            val cntError = CompError("form-error").also{ it.comp(itemView, K.itemList_cntError) }
                            val qtyInput = CompInputNumber{
                                it.inputClass = "form-input margin-left15"
                                it.maxValue = 9999
                                it.maxLength = 4
                                it.vali = ValiShipperItemQty
                                it.blurBlock = {
                                    eLaunch {
                                        if(App.checkAll(it)){
                                            d.qty = it.out()
                                        }
                                    }
                                }
                                it.errorListener = {isOk, msg->
                                    eLaunch{
                                        it.error(isOk)
                                        cntError.update(isOk, msg)
                                    }
                                }
                            }.also{
                                it.comp(itemView, K.itemList_inputCnt)
                            }
                            qtyInput.value.inputValue(d.qty)
                            d.view = qtyInput
                            itemView.sub(K.itemList_unit).html = "개"
                            itemView.sub(K.itemList_cBtn).click={ _, _->
                                itemInputData.dataList += CompInputData.InputData(d.itemRowid, "${d.itemTitle} | ${d.supplierTitle}", "${d.itemTitle}${d.supplierTitle}")
                                entity.itemList.removeAt(idx)
                                eLaunch { view.entity(entity) }
                            }
                        }
                    }
                }
            }
        }
    })
    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit) {
            view.clearCompValue(K.date, K.selectPacker, K.inputReceiver, K.selectDriver, K.inputItem, K.inputMemo)
            reqEnt = EntUserApiShipperDeliveryRequestWpReq()
            reqEntItemList = EntClientShipperDeliveryRequestAddItemList()
            view.entity(reqEntItemList)
            view.sub(K.addrArea).displayNone()
        }
    })
}