package comp.input

import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement

// maxLength 사용 금지 (제거 혹은 변경 예정)
abstract class CompInputSingle<V>:CompInput<String, V, V>{
    companion object{
        private const val ERROR_CLASS:String = "error"
        private const val SELECTED_CLASS:String = "selected"
        private const val DISABLED_CLASS:String = "disabled"
        var inputFocus:()->Unit = {}
        var inputBlur:()->Unit = {}
    }
    protected lateinit var target:eView<HTMLElement>
    override lateinit var value:CompValue<String, V>
    final override var errorListener:((Boolean, String)->Unit)? = null
    final override var vali:eVali? = null
    final override suspend fun init(it:eView<HTMLElement>){
        target = it.sub(subKey){
            it.value = ""
            it.className = setClassName("")
            it.placeholder = placeholder
            it.disabled = isDisabled
            it.focus = { _,_-> if(it.disabled == false) eLaunch{
                focus()
                inputFocus()
            } }
            it.blur = { _,_-> if(it.disabled == false) eLaunch{
                blur()
                inputBlur()
            } }
            it.keyup = { e,el->
                val ev = eEvent(e, el)
                changedValue(ev.value, true)
                if(ev.keycode() == 13) eLaunch{ enterEvent?.invoke(this) }
                keyUpBlock?.invoke(ev.value)
            }
            //TODO:: num lock 일때 .도 커버해야 함
            it.keydown = { e, el ->
                val ev = eEvent(e, el)
                if(maxLength > -1 && ev.keycode() !in hashSetOf(8,13,45,46,33,34,35,36,37,38,39,40) && ev.value.length >= maxLength) {
                    e.stopImmediatePropagation()
                    ev.prevent()
                }
                keyDownBlock?.invoke(ev.value)
            }
            it.change = { e,el->
                val v = eEvent(e, el).value.trim()
                changedValue(changeBlock?.invoke(v) ?: v)
            }
        }
        afterTargetInited?.invoke()
    }
    final override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName(if(isOk) { if(isFocus) SELECTED_CLASS else "" } else ERROR_CLASS)
    }
    final override suspend fun clear(){
        value.isOk = true
        value.inputValue("")
        enable(true)
    }

    protected open fun changedValue(v:String, isViewOnly:Boolean = false){
        elValue = v
        target.value = if(isViewOnly) eViewOnly(v) else v
        value.inputValue(v)
        if(isChangeCheck) eLaunch{ value.check() }
    }
    protected var afterTargetInited:(()->Unit)? = null
    var maxLength:Int = -1
    override var placeholder = ""

    var enterEvent:(suspend (CompInputSingle<V>)->Unit)? = null
    var isChangeCheck = false
    var focusBlock:(()->Unit)? = null
    var blurBlock:(()->Unit)? = null
    var changeBlock:((String)->String)? = null
    var keyDownBlock:((String)->Unit)? = null
    var keyUpBlock:((String)->Unit)? = null
    var elValue:String=""
    override var tabIndex = -2

    private var isFocus = false
    private fun focus(){
        isFocus = true
        target.className = setClassName(SELECTED_CLASS)
        focusBlock?.invoke()
    }
    private fun blur(){
        isFocus = false
        target.className = setClassName(if(value.isOk) "" else ERROR_CLASS)
        blurBlock?.invoke()
    }

    var isDisabled = false
    fun enable(v:Boolean){
        isDisabled = !v
        target.disabled = isDisabled
        if(isFocus) focus() else blur()
    }
    fun runFocus(){
        target.runFocus = false
        target.runFocus = true
    }
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayFlex() = target.displayFlex()
    fun placeholder(v:String){ target.placeholder = v }
    abstract var subKey:String
    var inputClass:String = "form-input"
    private fun setClassName(cls:String) = "$inputClass ${if(isDisabled) DISABLED_CLASS else cls}"
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value })
}