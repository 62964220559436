package boundxDriver.driver

object RouterKeyAppDriverWeb{
    const val TEST = "test"

    const val CERTIFICATE = "certificate" // 출하증명서

    const val START = "start"
    const val MENU = "menu"
    const val DRIVER_EDIT = "11"
    //-----------------------------------------------------subShipper
    const val SUB_SHIPPER_HOME = "20" // 배송의뢰사 기능 > 홈
    const val SUB_SHIPPER_REQUEST = "21" //배송 의뢰
    const val SUB_SHIPPER_DELIVERY_LIST = "22"
}