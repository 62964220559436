package shipper.deliveryRequest

import app.ApiShipper
import domPage.PageBase
import boundxDriver.common.entity.api.user.EntUserApiShipperDeliveryRequestWReq
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class DeliveryRequestAddP(router:eRouter<HTMLElement>): PageBase(::DeliveryRequestAddView, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        ApiShipper.shipperDeliveryRequestW(EntUserApiShipperDeliveryRequestWReq().apply{
            userId = ApiShipper.userId
        })?.also{
            view?.entity(it)
        }
    }
}