package entity

import ein2b.core.entity.eEntity

object EntInit:eEntity()
object EntEmpty:eEntity()
class EntMenu(key:String):eEntity(){
    val urlKey:String by string(::urlKey){ default(key) }
}
class EntValueTitle:eEntity(){
    companion object{
        operator fun invoke(value:String, title:String) = EntValueTitle().also {
            it.value = value
            it.title = title
        }
    }
    var value:String by string(::value)
    var title:String by string(::title)
}

class EntTopGnb:eEntity(){
    var userName:String by string(::userName)
}