package driver.edit

import Factory
import app.ApiDriver
import app.App
import app.DriverApp
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiDriverERes
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiDriverEpReq
import comp.CompWebAppHeader
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntDriverProfile
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionText
import view.CompViewToast

private val factory = Factory.htmlUrl("driver/edit/driverEdit")
private val profileItem = Factory.htmlUrl("driver/edit/profileItemT")

private enum class K{
   driverName, profile, profileList, uploadBtn, editBtn,
   list_img, list_selected;
   override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun DriverEdit() = eView(factory){ rootView->
   CompWebAppHeader(rootView){
      it.title = "c@기사 정보 변경@driver_app/button/driver"
      it.backClick = { DriverApp.back() as Unit }
   }
   var entClient = EntDriverProfile()
   val editForm = EntAppDriverWebApiDriverEpReq().also{
      it.phone = ApiDriver.member.phone
   }
   rootView.compLabelInputSectionTextSet(K.driverName, "c@기사명@driver_app/label/name", editForm.getVali(editForm::name))
   val uploadBtnView = rootView.sub(K.uploadBtn){
      it.html = "c@파일 업로드@driver_app/button/upload"
   }
   var intervalId = -1
   uploadBtnView.click = {_,_->
      DriverApp.upload()
      intervalId = window.setInterval({
         val isUpload = DriverApp.isUpload() as Boolean
         if(isUpload){
            eLaunch{
               val file = DriverApp.uploadUrl() as String
               if(file.isBlank()) CompViewToast.open("업로드에 실패했습니다.<br>다시 시도해주세요.")
               else if(file != "cancel"){
                  entClient.profileUrl = file
                  entClient.profileUrlList.forEach{ d-> d.selected = false }
                  rootView.entity(entClient)
               }
               window.clearInterval(intervalId)
               window.requestAnimationFrame{
                  DriverApp.loadingClose() as Unit
               }
            }
         }
      }, 100)
   }
   App.emptySub(rootView, K.profile, K.profileList)
   rootView.sub(K.editBtn).html = "c@기사 정보 변경@driver_app/button/driver/infochange"
   rootView.addEntityHook(EntAppDriverWebApiDriverERes::class, object:eEntityHook<HTMLElement, EntAppDriverWebApiDriverERes>{
      override suspend fun invoke(view:eView<HTMLElement>, entity:EntAppDriverWebApiDriverERes){
         view.entity(EntDriverProfile(entity))
         val driverName = view.sub(K.driverName).compLabelInputSectionText()
         driverName.input.value.inputValue(ApiDriver.member.userName)
         view.sub(K.profile).lazyBackgroundImage = "" to ApiDriver.member.profileUrl
         view.sub(K.editBtn).click = {_,_ ->
            if(App.checkAll(driverName)){
               eLaunch{
                  ApiDriver.driverEp(editForm.also{
                     it.driverRowid = ApiDriver.member.driverRowid
                     it.name = driverName.out()
                     it.profileUrl = entClient.profileUrl
                  })?.also{
                     DriverApp.back() as Unit
                  }
               }
            }
         }
      }
   })
   rootView.addEntityHook(EntDriverProfile::class, object:eEntityHook<HTMLElement, EntDriverProfile>{
      override suspend fun invoke(view:eView<HTMLElement>, entity: EntDriverProfile){
         entClient = entity
         view.sub(K.profile).lazyBackgroundImage = "" to entity.profileUrl
         view.sub(K.profileList).setClearList{ profileListList->
            entity.profileUrlList.forEach{ profileData->
               profileListList += eView(profileItem){ profileView->
                  profileView.sub(K.list_img){
                     it.lazyBackgroundImage = "" to profileData.img
                     it.click = {_,_->
                        eLaunch{
                           entity.profileUrlList.forEach{ d-> d.selected = false }
                           entity.profileUrl = profileData.img
                           profileData.selected = true
                           rootView.entity(entity)
                        }
                     }
                  }
                  profileView.sub(K.list_selected){
                     if(profileData.selected) it.displayBlock() else it.displayNone()
                  }
               }
            }
         }
      }
   })
}