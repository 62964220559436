package boundxDriver.common.enm.db

//인가 종류(메뉴별로 적용)
enum class EnumAuthorityCat(val roleCat: EnumRoleCat, val rowid:Int, val title:String, val isPrivate:Boolean, val partnerRoleCat: EnumRoleCat?) {
    //배송의뢰사(shipper)
    SHIPPER_DELIVERY_REQUEST(EnumRoleCat.SHIPPER, 101, "배송 의뢰", false, null),
    SHIPPER_DELIVERY_LIST(EnumRoleCat.SHIPPER, 102, "배송 리스트", false, null),
    SHIPPER_ITEM_MANAGEMENT(EnumRoleCat.SHIPPER, 103, "의약품 관리", false, null),
    SHIPPER_PACKER_MANAGEMENT(EnumRoleCat.SHIPPER, 104, "포장사 관리", false, EnumRoleCat.PACKER),
    SHIPPER_DRIVER_MANAGEMENT(EnumRoleCat.SHIPPER, 105, "기사 그룹 관리", false, null),

    //포장사(packer)
    PACKER_PACKAGE_LIST(EnumRoleCat.PACKER, 201, "포장 리스트", false, null),
    PACKER_DELIVERY_LIST(EnumRoleCat.PACKER, 202, "배송 리스트", false, null),
    PACKER_SENSOR_SESSION(EnumRoleCat.PACKER, 203, "온도 기록", false, null),
    PACKER_SENSOR_MAPPING(EnumRoleCat.PACKER, 204, "송장-센서 매핑", false, null),
    PACKER_DELIVERY_INFO(EnumRoleCat.PACKER, 205, "송장 정보 입력", false, null),
    PACKER_DECLINE_LIST(EnumRoleCat.PACKER, 206, "인수 거절 보고 내역", false, null),
    PACKER_CARRIER_MANAGEMENT(EnumRoleCat.PACKER, 207, "배송사 관리", false, EnumRoleCat.CARRIER),
    PACKER_DRIVER_MANAGEMENT(EnumRoleCat.PACKER, 208, "기사 그룹 관리", false, null),
    PHARMACISTS(EnumRoleCat.PACKER, 209, " 출하 확인 (약사 계정 전용)", true, null),

    //배송사
    CARRIER_DELIVERY_LIST(EnumRoleCat.CARRIER, 301, "배차 리스트", false, null),
    CARRIER_DRIVER_MANAGEMENT(EnumRoleCat.CARRIER, 302, "기사 그룹 관리", false, null),

    //수령사
    RECEIVER_DELIVER_LIST(EnumRoleCat.RECEIVER, 401, "수령 리스트", false, null),
    RECEIVER_ADDR_MANAGEMENT(EnumRoleCat.RECEIVER, 402, "주소 관리", false, null)
    ;
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 인가 입니다. i=$i")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 인가입니다. i=$i")
        private val mapOfRoleCat by lazy{
            values().groupBy { it.roleCat }
        }

        //특정 역할에 대한 인가 리스트
        fun listOfRole(roleCat: EnumRoleCat) = mapOfRoleCat[roleCat]!!

    }
    //서브 회원 등록시 사용할 수 있는 인가
    fun isForSubGeneralMemberJoin() = when(this){
        PHARMACISTS ->false
        else-> true
    }

}