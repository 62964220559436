package app

import boundxDriver.common.entity.api.app.driver.AppDriverResponse
import boundxDriver.common.entity.api.app.driver.EntAppDriverApiAccount
import boundxDriver.common.entity.api.app.driver.EntAppDriverApiReq
import boundxDriver.common.entity.api.app.driver.EntAppDriverApiResponse
import boundxDriver.common.entity.api.app.driver.web.*
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.*
import kotlinx.browser.window
import m42.api.M42ApiRsc
import view.CompViewAlert
import view.CompViewBlocking
import view.CompViewToast
import kotlin.js.Date

external val DriverApp:dynamic
object ApiDriver{
    val domain = if(window.location.hostname.indexOf("bxdriver10") == -1) "http://${window.location.hostname}:${window.location.port}" else "https://bxdriver10api.mobility42.io"
    init{
        M42ApiRsc.domain = domain
        eApi.sender = FetchSender()
    }
    private fun memberInit() = EntAppDriverApiAccount.Login().also{
        it.isAnonymous = true
        it.driverRowid = ""
        it.userName = ""
        it.phone = ""
        it.profileUrl = ""
    }
    var isLogin = false
    var member = memberInit()

    private const val TIMEOFFSET_KEY = "timeoffset"
    private const val ENTITY_KEY = "entity"

    private val apiMap = hashMapOf<String, eApi>()
    private suspend fun <RES:eEntity> net(reqEntity:EntAppDriverApiReq, resEntity:RES, isJson:Boolean = false, vararg infoItems:Pair<String, Any>):RES?{
        CompViewBlocking.open()

        val argItems:MutableList<Pair<String, Any>> = mutableListOf(TIMEOFFSET_KEY to "${Date().getTimezoneOffset()}", ENTITY_KEY to { reqEntity })
        infoItems.forEach{ argItems += it }

        val infoUrl = "${domain}${reqEntity._url(isJson)}"
        val api = apiMap[infoUrl] ?: eApi("", eApi.DEFAULT to eApiInfo{
            method = eApi.POST

            url = infoUrl
            items += argItems.map{ it.first }

            requestTask += eRequestTask.Header(TIMEOFFSET_KEY)
            requestTask += eRequestTask.JsonFromEntity(ENTITY_KEY)

            responseTask += eResponseTask.Text
            responseTask += eResponseTask.Entity{ AppDriverResponse{ resEntity } }
        })
        return api(*argItems.toTypedArray()).let{ result->
            CompViewBlocking.close()
            isLogin = false
            member = memberInit()
            if(result.isOk){
                @Suppress("UNCHECKED_CAST")
                (result.response!!.result as? AppDriverResponse<RES>)?.let{ res->
                    isLogin = res.isLogin
                    if(isLogin) member = res.account as EntAppDriverApiAccount.Login
                    if(res.isError){
                        errorMethod(res.error)
                        null
                    }else res.data
                }
            }else{
                log("result.err::${result.err}")
                null
            }
        }
    }
    suspend fun errorMethod(e:EntAppDriverApiResponse.Error, block:(()->Unit)? = null) = when(e.method){
        "login"-> DriverApp.login()
        "alert"-> CompViewAlert.open(e.message, block = block)
        "toast"->{
            if(block != null) block()
            CompViewToast.open(e.message)
        }
        else-> console.log("error::", e.message)
    }
    
    suspend fun logincheck():EntAppDriverWebApiLoginCheckRes? = net(EntAppDriverWebApiLoginCheckReq().also{
        it.phone = DriverApp.phone() as String
        it.token = DriverApp.atk() as String
    }, EntAppDriverWebApiLoginCheckRes())
    suspend fun menu():EntAppDriverWebApiMenuRes? = net(EntAppDriverWebApiMenuReq().also{
        it.phone = member.phone
    }, EntAppDriverWebApiMenuRes())
    suspend fun certificate(req:EntAppDriverWebApiCertificateReq):EntAppDriverWebApiCertificateRes? = net(req, EntAppDriverWebApiCertificateRes())
    suspend fun driverE(req:EntAppDriverWebApiDriverEReq):EntAppDriverWebApiDriverERes? = net(req, EntAppDriverWebApiDriverERes())
    suspend fun driverEp(req:EntAppDriverWebApiDriverEpReq):EntAppDriverWebApiDriverEpRes? = net(req, EntAppDriverWebApiDriverEpRes())
}