package start

import Factory
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import entity.EntInit
import org.w3c.dom.HTMLElement

private val factory = Factory.html("<section></section>")
suspend fun Start() = eView(factory){ rootView->
    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntInit){

        }
    })
}