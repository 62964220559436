package view

import Factory
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

object CompViewConfirm{
    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement){
        //language=html
        val f = Factory.html("""
<section id="confirm" class="fixed-block">
    <div class="contents">
        <div data-view="msg" class="msg"></div>
        <div class="btn-wrap">
            <div data-view="cancel" class="btn border">NOT NOW
            </div><div data-view="confirm" class="btn">DELETE</div>
        </div>
    </div>
</section>
""")
        target = eView(f){
            it.displayNone()
            it.sub("msg")
            it.sub("cancel")
            it.sub("confirm")
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }

    private const val CONFIRM_LABEL = "확인"
    private const val CANCEL_LABEL = "취소"
    suspend fun open(msg:String, confirmLabel:String = "", cancelLabel:String = "", cancelClick:(()->Unit)? = null, confirmClick:()->Unit){
        if(msg.isBlank()) return

        target.displayBlock()
        target.sub("msg").html = msg
        target.sub("cancel"){
            it.html = cancelLabel.ifBlank{ CANCEL_LABEL }
            it.click = {_,_->
                target.displayNone()
                cancelClick?.invoke()
            }
        }
        target.sub("confirm"){
            it.html = confirmLabel.ifBlank{ CONFIRM_LABEL }
            it.click = {_,_->
                target.displayNone()
                confirmClick()
            }
        }
    }
}