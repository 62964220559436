package boundxDriver.common.entity.api.app.driver.web

import ein2b.core.entity.eEntity
import boundxDriver.common.entity.api.app.driver.APP_DRIVER_API_PATH
import boundxDriver.common.entity.api.app.driver.EntAppDriverApiAuthWReq
import boundxDriver.common.entity.api.app.driver.EntAppDriverApiReq
import boundxDriver.common.vali.ValiDeliveryPackageR
import boundxDriver.common.vali.ValiDriverImageUrl
import boundxDriver.common.vali.ValiDriverName
import boundxDriver.common.vali.ValiDriverR
import boundxDriver.common.entity.api.app.EntTempInfo

const val DRIVER_WEB_API_PATH = "$APP_DRIVER_API_PATH/web"
//--- 앱에서 웹으로 로그인 체크 -----------------------------------------------------------------
open class EntAppDriverWebApiLoginCheckReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$DRIVER_WEB_API_PATH/login/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
    var token:String by string(::token)
}
class EntAppDriverWebApiLoginCheckRes:eEntity()
//--- 메뉴 -----------------------------------------------------------------
class EntAppDriverWebApiMenuReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$DRIVER_WEB_API_PATH/menu"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverWebApiMenuRes:eEntity()

//--- 기사 정보 수정 준비-----------------------------------------------------------------
class EntAppDriverWebApiDriverEReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$DRIVER_WEB_API_PATH/driver/e"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
    var driverRowid:String by string(::driverRowid){ validator(ValiDriverR) }
}
class EntAppDriverWebApiDriverERes:eEntity(false){
    var profileUrlList: MutableList<String> by stringList(::profileUrlList){
        default(mutableListOf(), false)
    }
}
//--- 기사 정보 수정-----------------------------------------------------------------
class EntAppDriverWebApiDriverEpReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$DRIVER_WEB_API_PATH/driver/ep"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
    var driverRowid:String by string(::driverRowid){ validator(ValiDriverR) }
    var profileUrl:String by string(::profileUrl){ validator(ValiDriverImageUrl) }
    var name:String by string(::name){ validator(ValiDriverName) }
}
class EntAppDriverWebApiDriverEpRes:eEntity(false)

//--- 출하증명서 조회 -----------------------------------------------------------------
class EntAppDriverWebApiCertificateReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$DRIVER_WEB_API_PATH/certificate/{deliveryPackageRowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{phone}", phone)
            .replace("{deliveryPackageRowid}", deliveryPackageRowid)
    var deliveryPackageRowid:String by string(::deliveryPackageRowid){validator(ValiDeliveryPackageR)}
}
class EntAppDriverWebApiCertificateRes:eEntity(){
    class Package:eEntity(true){
        //판매(출하)담당자
        class Shipper:eEntity(true){
            var name:String by string(::name) //상호 - 대웅제약
            var addr:String by string(::addr) //주소 - 서울시 마포구 도화동 173
            var deliveryDate:String by string(::deliveryDate) //발송일시 ex)2021-11-27(화) 09:30
            var itemCondition:String by string(::itemCondition) //저장방법 ex)냉장 (0°C ~ 10°C)
            var itemPackage:String by string(::itemPackage) //포장형태 ex)Ice Box(다 형)
            var personName:String by string(::personName) //현지성
            var signUrl:String by string(::signUrl) //판매 담당자 서명
        }
        //수령자
        class Receiver:eEntity(true){
            var name:String by string(::name) //상호 ex) 분당서울대병원
            var addr:String by string(::addr) //주소
            var completeDate:String by string(::completeDate) //수령일 ex)2021-10-27(수) 11:00
            var lastTemp:String by string(::lastTemp) //수령시 제품 온도 - 15.1
            //var personName:String by string(::personName) //강영환
            var nameUrl:String by string(::nameUrl) //강영환
            var signUrl:String by string(::signUrl) //수령자 사인
        }
        //제조(수입업자)와 제품
        class SupplierAndItem:eEntity(true){
            var name:String by string(::name) //상호
            var addr:String by string(::addr) //제조(수입)업자 주소
            var itemTitle:String by string(::itemTitle) //제품명 ex)코미나티주(토지나메란)
            var serialNum:String by string(::serialNum) //제조번호
            var validDate:String by string(::validDate) //유효기간
            var unitType:String by string(::unitType) //규격 - vial or 100 vials/box
            var qty:String by string(::qty) //수량 1
        }
        val shipper: Shipper by entity(::shipper, ::Shipper){ default(Shipper(), false) }
        val receiver: Receiver by entity(::receiver, ::Receiver){ default(Receiver(), false) }
        val supplierAndItemList: MutableList<SupplierAndItem> by entityList(::supplierAndItemList, ::SupplierAndItem){ default(mutableListOf(), false) }
        var shipDate:String by string(::shipDate)//출하일 - 2021년 11월 27일
    }
    var detail: Package by entity(::detail, ::Package)
}


//--- 온도 태그 -----------------------------------------------------------------
class EntAppDriverWebApiDeliveryPackageTempReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$DRIVER_WEB_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}/temp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
}
class EntAppDriverWebApiDeliveryPackageTempRes:eEntity(){
    var startDate:String by string(::startDate) //2022-01-10 13:50:00
    var endDate:String by string(::endDate) //2022-01-10 13:50:00
    var tempInfoList:MutableList<EntTempInfo> by entityList(::tempInfoList, ::EntTempInfo){ default(mutableListOf(), false) }
}