package m42.app

import ein2b.core.core.eEncodeQueryString
import ein2b.core.entity.eEntity
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.router.eHashManager
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

object M42App{
    lateinit var hashManager:eHashManager
    suspend fun subHtmlFromEntity(view:eView<HTMLElement>, entity:eEntity, vararg keys:Any){
        entity.forEach(*keys.map{ "$it" }.toTypedArray()){ k, v-> view.sub(k){ it.html = "$v" } }
    }
    suspend fun subHtmlFromLabel(view:eView<HTMLElement>, vararg keys:Pair<Any, String>){
        keys.forEach{ (k, v) -> view.sub(k){ it.html = v} }
    }
    suspend fun emptySub(view:eView<HTMLElement>, vararg keys:Any){ keys.forEach{ view.sub(it) } }
    fun reload() = window.location.reload()

    private fun path(k:String, vararg arg:Pair<String, Any>) = if(arg.isEmpty()) k else "$k?${eEncodeQueryString(*arg)}"
    fun goUrl(k:String, vararg arg:Pair<String, Any>){
        val path = path(k, *arg)
        val url = "${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}"
        if(window.location.href == url) window.location.reload() else hashManager.goUrl(k, *arg)
    }
    fun moduleUrl(module:String, k:String, vararg arg:Pair<String, Any>){
        window.location.href = "$module#${path(k, *arg)}"
    }
}