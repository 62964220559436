package driver.menu

import Factory
import app.ApiDriver
import app.App
import app.DriverApp
import app.hashManager
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiMenuRes
import boundxDriver.driver.RouterKeyAppDriverWeb
import boundxDriver.driver.RouterKeyAppDriverWeb.DRIVER_EDIT
import comp.CompWebAppHeader
import comp.input.CompToggleRadio
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import m42.api.M42ApiRsc
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("driver/menu/menu")
private enum class K{
    ANONYMOUS, LABEL2,
    userName, phone, anonymousWrap, memberWrap, shipperBtn, editBtn, logoutBtn, phoneBtn, profileImage,
    lnList;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun Menu() = eView(factory){ rootView->
    CompWebAppHeader(rootView){
        it.title = "c@메뉴@driver_app/title/menu"
        it.backClick = { DriverApp.back() as Unit }
    }
    App.subHtmlFromLabel(rootView,
        K.ANONYMOUS to "c@미인증 상태로 이용 중입니다.@driver_app/text/nonverifieddriver",
        K.LABEL2 to "c@배송의뢰사 기능@driver_app/title/client"
    )
    App.emptySub(rootView, K.userName, K.phone, K.anonymousWrap, K.memberWrap, K.profileImage)

    rootView.sub(K.shipperBtn){ it.click = {_,_-> hashManager.goUrl(RouterKeyAppDriverWeb.SUB_SHIPPER_HOME)} }
    rootView.sub(K.editBtn){
        it.html = "c@기사 정보 변경@driver_app/button/driver"
        it.click = {_,_-> hashManager.goUrl(DRIVER_EDIT, "driverRowid" to ApiDriver.member.driverRowid)}
    }
    rootView.sub(K.logoutBtn){
        it.html = "c@로그 아웃@driver_app/button/logout"
        it.click = {_,_-> DriverApp.logout() as Unit}
    }
    rootView.sub(K.phoneBtn){
        it.html = "c@휴대폰번호 인증@driver_app/button/label/mobile"
        it.click = {_,_->  DriverApp.logout() as Unit}
    }
    CompToggleRadio<String>{ comp->
        comp.wrapperClass = "toggle-list"
        comp.afterInited = {
            comp.setList{
                listOf("ko" to "c@한국어@driver_app/title/language/ko", "en" to "c@영어@driver_app/title/language/en").map{ (ln, title) ->
                    comp.item(title, ln, M42ApiRsc.catMap["ln"] == ln)
                }
            }
        }
        comp.checkBlock = { idx->
            eLaunch{
                val ln = comp.itemList[idx].value
                DriverApp.languageSet(ln)
                M42ApiRsc.cat("ln", ln)
                M42ApiRsc.rsc("driver_app", "login", "deliveryrequest")
            }
        }
    }.also{ comp->
        comp.comp(rootView, K.lnList)
    }
    rootView.addEntityHook(EntAppDriverWebApiMenuRes::class, object:eEntityHook<HTMLElement, EntAppDriverWebApiMenuRes>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntAppDriverWebApiMenuRes){
            if(ApiDriver.member.isAnonymous){
                view.sub(K.anonymousWrap).displayBlock()
                view.sub(K.memberWrap).displayNone()
            }else{
                view.sub(K.anonymousWrap).displayNone()
                view.sub(K.memberWrap).displayBlock()
                view.sub(K.userName).html = ApiDriver.member.userName
                view.sub(K.phone).html = ApiDriver.member.phone
                view.sub(K.profileImage).lazyBackgroundImage = "" to ApiDriver.member.profileUrl
            }
        }
    })
}