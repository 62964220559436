package driver.certificate

import Factory
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiCertificateRes
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.lazyBackgroundImage
import m42.app.M42App
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("driver/certificate/certificate")
private val itemFactory = Factory.htmlUrl("driver/certificate/itemT")

private enum class K{
    shipperName, shipperAddr, deliveryDate, itemCondition, itemPackage,
    receiverName, receiverAddr, completeDate, lastTemp, itemList, shipDate,
    shipperPersonName, shipperSignUrl, receiverNameUrl, receiverSignUrl,

    list_name, list_addr, list_itemTitle, list_serialNum, list_validDate, list_itemStandard, list_qty;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun Certificate() = eView(factory){ rootView ->
    M42App.emptySub(rootView, K.shipperName, K.shipperAddr, K.deliveryDate, K.itemCondition, K.itemPackage,
        K.receiverName, K.receiverAddr, K.completeDate, K.lastTemp, K.itemList, K.shipDate,
        K.shipperPersonName, K.shipperSignUrl, K.receiverNameUrl, K.receiverSignUrl
    )
    rootView.addEntityHook(EntAppDriverWebApiCertificateRes::class, object:eEntityHook<HTMLElement, EntAppDriverWebApiCertificateRes>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntAppDriverWebApiCertificateRes){
            val detail = entity.detail
            view.sub(K.shipperName).html = detail.shipper.name
            view.sub(K.shipperAddr).html = detail.shipper.addr
            view.sub(K.deliveryDate).html = detail.shipper.deliveryDate
            view.sub(K.itemCondition).html = detail.shipper.itemCondition
            view.sub(K.itemPackage).html = detail.shipper.itemPackage
            view.sub(K.receiverName).html = detail.receiver.name
            view.sub(K.receiverAddr).html = detail.receiver.addr
            view.sub(K.completeDate).html = detail.receiver.completeDate
            view.sub(K.lastTemp).html = detail.receiver.lastTemp
            /*App.setCommonList(
                view.sub(K.itemList), itemFactory, detail.supplierAndItemList,
                K.list_name, K.list_addr, K.list_itemTitle, K.list_serialNum,
                K.list_validDate, K.list_itemStandard, K.list_qty
            )*/
            view.sub(K.shipDate).html = detail.shipDate
            view.sub(K.shipperPersonName).html = detail.shipper.personName
            view.sub(K.shipperSignUrl).lazyBackgroundImage = "" to detail.shipper.signUrl
            view.sub(K.receiverNameUrl).lazyBackgroundImage = "" to detail.receiver.nameUrl
            view.sub(K.receiverSignUrl).lazyBackgroundImage = "" to detail.receiver.signUrl
        }
    })
}