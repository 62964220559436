package comp.input

import Factory
import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

//setList를 사용하지않고 setGroupList를 사용해서 만듦
//옵션에 나오는 그룹의 텍스트는 groupList에서 받는 맵들의 key
//밑에 selectedText는 옵션을 선택한경우 셀렉트박스의 인풋에 어떤 형태로 나타나게 할건지 텍스트를 꾸며주는 기능
//CompSelectAddOn은 셀렉트박스에 숨김표시 / 리스트 부분 있는 셀렉트박스
//체크박스의 라벨과 링크의 라벨 / 이벤트를 전달할 수 있음
class CompGroupSelect<V>:CompInputMulti<V, V>(){
    companion object{
        private val LIST = mutableListOf<CompGroupSelect<*>>()
        private const val CLASS_NAME = "CompGroupSelect"
        private const val SELECT = "${CLASS_NAME}_select"
        private const val ARROW = "${CLASS_NAME}_arrow"
        private const val GROUP_LIST = "${CLASS_NAME}_group_list"
        private const val GROUP_ITEM = "${CLASS_NAME}_group_item"
        private const val GROUP_TITLE = "${CLASS_NAME}_group_title"
        private const val ITEM_LIST = "${CLASS_NAME}_item_list"
        private const val ITEM_ITEM = "${CLASS_NAME}_item_item"
        private const val SELECTED_CLASS = "selected"
        private const val FOCUSED_CLASS = "focused"
        private const val DISABLED_CLASS = "disabled"
        private const val ERROR_CLASS = "error"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="$ITEM_ITEM" class="item-item"></li>""")
        private val groupFactory:suspend ()-> HTMLElement = Factory.html("""
            <li data-view="$GROUP_ITEM">
                <div data-view="$GROUP_TITLE" class="input-block-label" style="cursor:default;padding:8px 0 0 10px;font-size:14px"></div>
                <ul data-view="$ITEM_LIST"></ul>
            </li>
        """)
        operator fun<V> invoke(block:(CompGroupSelect<V>)->Unit): CompGroupSelect<V> {
            val comp = CompGroupSelect<V>()
            LIST.add(comp)
            block(comp)
            comp.afterTargetInited = {
                comp.target.sub(SELECT).html = comp.placeholder
                comp.target.sub(ARROW)
                comp.target.sub(GROUP_LIST).className = if(comp.isReverse) "item-list-reverse" else "item-list"
                comp.itemListDisplay()
                comp.target.click = {e,_->
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    if(!comp.isDisabled){
                        eLaunch{
                            if(!comp.isItemListOpen) LIST.forEach{ it.itemListDisplay(false) }
                            comp.itemListDisplay(!comp.isItemListOpen)
                        }
                    }
                }
                eWindow.addClick{ eLaunch{ comp.itemListDisplay(false) } }
            }
            return comp
        }
    }

    private var isItemListOpen = false
    suspend fun itemListDisplay(isOpen:Boolean = false){
        isItemListOpen = isOpen
        target.sub(GROUP_LIST){ if(isItemListOpen) it.displayBlock() else it.displayNone() }
        target.className = setClassName()
    }
    override val subKey:String = "${CLASS_NAME}_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""
    <div data-view="$subKey">
        <div data-view="$subKey.$SELECT" class="selectbox ellipsis"></div><div data-view="$subKey.$ARROW" class="arrow"></div>
        <ul data-view="$subKey.$GROUP_LIST" class="item-list"></ul>
    </div>""")
    suspend fun setGroupList(groupMap:Map<String, List<Item<V>>>) {
        this.groupMap = groupMap.toMutableMap()
        itemList = arrayOf()
        isOk = true
        value.inputValue(listOf())
        if(groupMap.isEmpty()) isDisabled = true
        target.className = setClassName()
        target.sub(GROUP_LIST).setClearList { vList ->
            var idx = 0
            groupMap.entries.forEach{ (group, list) ->
                vList += eView(groupFactory) { groupView ->
                    groupView.sub(GROUP_ITEM){
                        it.click = { e, _ ->
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                        }
                    }
                    groupView.sub(GROUP_TITLE){ it.html = group }
                    groupView.sub(ITEM_LIST).setClearList { ivList ->
                        list.forEach{item ->
                            itemList += item
                            ivList += eView(itemFactory){ itemView ->
                                itemView.sub(ITEM_ITEM) {
                                    item.view = it
                                    item.idx = idx
                                    it.html = item.label
                                    it.className = setClassName(item)
                                    it.click = { e, _ ->
                                        e.stopPropagation()
                                        e.stopImmediatePropagation()
                                        if(!item.isDisabled) eLaunch{
                                            isOk = true
                                            itemList.forEach{ listItem->
                                                listItem.isSelect = false
                                                listItem.view?.className = setClassName(listItem)
                                            }
                                            item.isSelect = true
                                            it.className = setClassName(item)
                                            itemListDisplay()

                                            value.inputValue(listOf(item.idx))
                                            checkBlock?.invoke(item.idx)
                                        }
                                    }
                                }
                            }
                            if(item.isSelect){
                                defaultIdx = item.idx
                                value.inputValue(listOf(item.idx))
                                initBlock?.invoke(item.idx)
                            }
                            idx++
                        }
                    }
                }
            }
        }
        if(itemList.all { !it.isSelect }) target.sub(SELECT).html = placeholder
    }
    suspend fun setDefault() {
        itemList.forEach{ listItem->
            listItem.isSelect = defaultIdx == listItem.idx
            listItem.view?.className = setClassName(listItem)
        }
        value.inputValue(if(defaultIdx == -1) listOf() else listOf(defaultIdx))
        if(itemList.all { !it.isSelect }) target.sub(SELECT).html = placeholder
    }
    override suspend fun setList(vararg list:Item<V>){}
    private fun setClassName():String = "$wrapperClass${if(isDisabled) " $DISABLED_CLASS" else if(isItemListOpen) " $FOCUSED_CLASS" else if(isOk) "" else " $ERROR_CLASS"}"
    private fun setClassName(item:Item<V>):String{
        if(item.isSelect) eLaunch{ target.sub(SELECT).html = selectedText?.let { it.invoke(item.idx) }?:item.label }
        return "item-item${if(item.isDisabled) " $DISABLED_CLASS" else if(item.isSelect) " $SELECTED_CLASS" else ""}"
    }
    override suspend fun error(isOK:Boolean){
        isOk = isOK
        target.className = setClassName()
    }
    private var isOk = true
    private var defaultIdx = -1
    var isDisabled = false
    var checkBlock:((idx:Int)->Unit)? = null
    var selectedText:((idx:Int)->String)? = null
    var initBlock:((idx:Int)->Unit)? = null
    var isReverse = false
    var groupMap:MutableMap<String, List<Item<V>>> = mutableMapOf()
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}