package shipper.home

import app.ApiShipper
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import entity.EntInit
import domPage.PageBase
import org.w3c.dom.HTMLElement

class ShipperHomeP(router:eRouter<HTMLElement>): PageBase(::HomeView, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        ApiShipper.loginCheck()?.also{
            view?.entity(EntInit)
        }
    }
}