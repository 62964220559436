package boundxDriver.common.enm

//[관리자]배송 리스트 검색용 타입
enum class EnumDeliverySearchType(val key:String, val label:String) {
    DELIVERY_NUMBER("deliveryNumber", "배송번호"),
    ITEM_TITLE("item", "의약품명"),
    SHIPPER_MEMBER("shipperMember", "작성자"),
    PACKER("packer", "포장사"),
    DRIVER("driver", "배송 기사"),
    RECEIVER("receiver", "수령사"),
    REG_DATE("regDate", "등록일");
    companion object{
        private val seperator = ": "
        operator fun invoke(key:String) = values().find{it.key == key}?: error("없는 검색 필터입니다.")
        fun EnumDeliverySearchType.getLabel(v:String) = "${this.label}$seperator$v"
    }
}