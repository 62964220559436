package boundxDriver.common.entity.api.app.driver

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import boundxDriver.common.enm.db.EnumPackageState
import boundxDriver.common.entity.api.app.EntTempInfoLog
import boundxDriver.common.vali.*

const val apkVersionUrl = "https://s3.ap-northeast-2.amazonaws.com/bxdriver10.mobility42.io/app_version_check"
const val apkDefaultUrl = "$apkVersionUrl/boundxDriverApp.apk"

const val APP_DRIVER_API_PATH = "/app/driver/api/{phone}"

//--- 업로드 -----------------------------------------------------------------
/* eRequestTask.ByteBody 로 file을 전달해야함
헤더에 추가해서 보내야함 "FILE_SIZE", "PHONE"*/
class EntAppDriverApiUploadReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/upload"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiUploadRes:eEntity(){
    var file:String by string(::file)
}
//--- 로그인 체크 -----------------------------------------------------------------
class EntAppDriverApiLoginCheckReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/login/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiLoginCheckRes:eEntity()
//--- 미인증 로그인 -----------------------------------------------------------------
class EntAppDriverApiAnonymousLoginReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/anonymous/login"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
    var guid:String by string(::guid)
}
class EntAppDriverApiAnonymousLoginRes:eEntity()
//--- 로그아웃 -----------------------------------------------------------------
class EntAppDriverApiLogoutReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/logout"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiLogoutRes:eEntity()
//--- 인증번호 발송 -----------------------------------------------------------------
open class EntAppDriverApiAuthWReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/auth/w"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiAuthWRes:eEntity(){
    var isSuccess: Boolean by bool(::isSuccess) //인증번호 발송 성공여부
}
//--- 인증번호 인증 -----------------------------------------------------------------
open class EntAppDriverApiAuthWpReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/auth/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
    var code:String by string(::code){ validator(ValiDriverAuthCode) }
}
class EntAppDriverApiAuthWpRes:eEntity(){
    var isSuccess: Boolean by bool(::isSuccess) //인증번호가 일치하지 않을경우 isSuccess = false
}
//--- 로그인 준비 -----------------------------------------------------------------
open class EntAppDriverApiLoginWReq:EntAppDriverApiAuthWpReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/login/w"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiLoginWRes:eEntity(){
    var profileImgList: MutableList<String> by stringList(::profileImgList){default(mutableListOf(), false)}
}
//--- 로그인 -----------------------------------------------------------------
open class EntAppDriverApiLoginReq:EntAppDriverApiAuthWpReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/login"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
    var userName:String by string(::userName){ validator(ValiDriverName) }
    var profileUrl:String by string(::profileUrl){ validator(ValiDriverImageUrl) }
}
class EntAppDriverApiLoginRes:eEntity()
//--- 홈 -----------------------------------------------------------------
open class EntAppDriverApiHomeReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/home"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiHomeRes:eEntity(){
    var deliveryCount:String by string(::deliveryCount)
    var returnCount:String by string(::returnCount){ default("0", false) }
}
//--- 송장 스캔(서명) : 해당 패키지가 배송중인지 확인 -----------------------------------------------------------------
open class EntAppDriverApiInvoicePackageDeliveryReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/{invoiceNum}/package/delivery"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{invoiceNum}", invoiceNum)
    var invoiceNum:String by string(::invoiceNum)
}
class EntAppDriverApiInvoicePackageDeliveryRes:eEntity(){
    var invoiceNum:String by string(::invoiceNum)
    var isBoundx: Boolean by bool(::isBoundx){ default(true, false) }

    class DeliveryPackageInvoice:eEntity(){
        var deliveryPackageRowid:String by string(::deliveryPackageRowid)
        var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
        var isExternal: Boolean by bool(::isExternal) //외부센서이면 true
    }
    var deliveryPackageInvoiceList: MutableList<DeliveryPackageInvoice> by entityList(::deliveryPackageInvoiceList, ::DeliveryPackageInvoice){ default(mutableListOf(), false) }

    //boundx의 모든패키지 리스트
    class DeliveryPackage:EntAppDriverApiBoundxDeliveryPackage(){
        var deliveryRowid:String by string(::deliveryRowid)
        var isScanned: Boolean by bool(::isScanned)
    }
    var boundxDeliveryPackageList: MutableList<DeliveryPackage> by entityList(::boundxDeliveryPackageList, ::DeliveryPackage){ default(mutableListOf(),false) }
}
//--- 송장 스캔(출하증명서) : 해당 패키지가 배송완료인지 확인 -----------------------------------------------------------------
class EntAppDriverApiInvoicePackageCertificateReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/{invoiceNum}/package/certificate"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{invoiceNum}", invoiceNum)
    var invoiceNum:String by string(::invoiceNum)
}
open class EntAppDriverApiInvoicePackageCertificateRes:eEntity(){
    var invoiceNum:String by string(::invoiceNum)
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
}
//--- 송장 스캔(취소) : 모든 패키지가 배송중인지 확인 -----------------------------------------------------------------
class EntAppDriverApiInvoicePackageCancelReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/{invoiceNum}/package/cancel"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{invoiceNum}", invoiceNum)
    var invoiceNum:String by string(::invoiceNum)
}
class EntAppDriverApiInvoicePackageCancelRes:eEntity(){
    var invoiceNum:String by string(::invoiceNum)
    var isBoundx: Boolean by bool(::isBoundx){ default(true, false) }
    var deliveryRowid:String by string(::deliveryRowid)
}
//--- 송장 리스트 -----------------------------------------------------------------
open class EntAppDriverApiInvoicePackageReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/{invoiceNum}/package"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{invoiceNum}", invoiceNum)
    var invoiceNum:String by string(::invoiceNum)
    var deliveryPackageRowidList: MutableList<String> by stringList(::deliveryPackageRowidList) //송장 스캔에서 받았던 패키지 일련번호 리스트
}
open class EntAppDriverApiInvoicePackageRes:eEntity(){
    var invoiceNum:String by string(::invoiceNum)
    class DeliveryPackageInvoice:eEntity(){
        var deliveryRowid:String by string(::deliveryRowid)
        var deliveryPackageRowid:String by string(::deliveryPackageRowid)
        var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
        var invoiceCompanyName:String by string(::invoiceCompanyName) //택배사 이름
        var packer: EntAppDriverApiPackerReceiver.Greenie by entity(::packer, EntAppDriverApiPackerReceiver::Greenie) //포장사
        var receiver: EntAppDriverApiPackerReceiver.Greenie by entity(::receiver, EntAppDriverApiPackerReceiver::Greenie) //수령사
    }
    var deliveryPackageInvoiceList: MutableList<DeliveryPackageInvoice> by entityList(::deliveryPackageInvoiceList, ::DeliveryPackageInvoice){
        default(mutableListOf(), false)
    }
}
//--- 패키지 송장 정보:서명 -----------------------------------------------------------------
open class EntAppDriverApiInvoicePackageDeliveryCheckReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/package/{deliveryPackageRowid}/delivery/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageRowid}", deliveryPackageRowid)
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
}
open class EntAppDriverApiInvoicePackageDeliveryCheckRes:eEntity(){
    var invoiceNum:String by string(::invoiceNum)
    var isExternal: Boolean by bool(::isExternal) //외부센서이면 true
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
}
//--- 패키지 송장 정보:출하증명서 -----------------------------------------------------------------
open class EntAppDriverApiInvoicePackageCertificateCheckReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/package/{deliveryPackageRowid}/certificate/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageRowid}", deliveryPackageRowid)
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
}
open class EntAppDriverApiInvoicePackageCertificateCheckRes:eEntity(){
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
}
//--- 패키지 송장 정보:배송취소 -----------------------------------------------------------------
open class EntAppDriverApiInvoicePackageCancelCheckReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/invoice/package/{deliveryRowid}/cancel/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryRowid}", deliveryRowid)
    var deliveryRowid:String by string(::deliveryRowid)
}
open class EntAppDriverApiInvoicePackageCancelCheckRes:eEntity(){
    var deliveryRowid:String by string(::deliveryRowid)
}
//--- 패키지 정보 -----------------------------------------------------------------
class EntAppDriverApiDeliveryPackageReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode) //0이면 외부센서
}
class EntAppDriverApiDeliveryPackageRes:eEntity(){
    companion object{
        val NO_TEMP_DETAIL = TempDetail().also{
            it.startDate = ""
            it.endDate = ""
            it.maxTemp = ""
            it.minTemp = ""
            it.lastTemp = ""
            it.isMaxTempErr = false
            it.isMinTempErr = false
            it.isLastTempErr = false
        }
    }
    class DeliveryItem:eEntity(){
        var title:String by string(::title)
        var qty:String by string(::qty) //1,000 vial or 5 boxes (500 vials) 포장형태 붙여서
    }
    class ItemCondition:eEntity(){
        var maxTemp:String by string(::maxTemp) //8.0 ℃
        var minTemp:String by string(::minTemp) //8.0 ℃
    }
    class TempDetail:eEntity(){
        var startDate:String by string(::startDate) //2022-01-10(월) 13:50
        var endDate:String by string(::endDate) //2022-01-10(월) 13:50
        var maxTemp:String by string(::maxTemp) //8.0 ℃
        var minTemp:String by string(::minTemp) //8.0 ℃
        var lastTemp:String by string(::lastTemp) //8.0 ℃
        var isMaxTempErr: Boolean by bool(::isMaxTempErr) //최고 온도 오류인 경우
        var isMinTempErr: Boolean by bool(::isMinTempErr) //최저 온도 오류인 경우
        var isLastTempErr: Boolean by bool(::isLastTempErr) //태그시 온도 오류인 경우
    }
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var shipnum:String by string(::shipnum)
    var sensorCode:String by string(::sensorCode) //0이면 외부센서

    var receiverAddr:String by string(::receiverAddr) //수령사 주소
    var deliveryItemList: MutableList<DeliveryItem> by entityList(::deliveryItemList, EntAppDriverApiDeliveryPackageRes::DeliveryItem){ default(mutableListOf(),false) }

    var itemCondition: ItemCondition by entity(::itemCondition, ::ItemCondition) //기준 온도
    var tempDetail: TempDetail by entity(::tempDetail, ::TempDetail){ default(NO_TEMP_DETAIL, false) } //센서 태그시 입력받은 기본 정보
}
//--- 센서 태그 -----------------------------------------------------------------
class EntAppDriverApiDeliveryPackageSensorReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}/sensor"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
    var startDate:String by string(::startDate)
    var endDate:String by string(::endDate)
    var minTemp:String by string(::minTemp)
    var maxTemp:String by string(::maxTemp)
    var lastTemp:String by string(::lastTemp)
}
class EntAppDriverApiDeliveryPackageSensorRes:eEntity(){
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
}
//--- 온도 태그 -----------------------------------------------------------------
class EntAppDriverApiDeliveryPackageTempReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}/temp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
    var tempInfoLog: EntTempInfoLog by entity(::tempInfoLog, ::EntTempInfoLog)
}
class EntAppDriverApiDeliveryPackageTempRes:eEntity(){
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
}
//--- 수령 완료 -----------------------------------------------------------------
/* eRequestTask.ByteBody 로 nameFile과 signFile의 byteArray를 합채서 file로 전달함
   헤더에 추가해서 보내야함 : "PHONE", "DELIVERYPACKAGE_INVOICE", "SENSOR_CODE", "LAST_TEMP", "NAME_FILE_SIZE", "SIGN_FILE_SIZE"
*/
class EntAppDriverApiDeliveryPackageSignatureReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}/signature"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
}
class EntAppDriverApiDeliveryPackageSignatureRes:eEntity()
//--- 수령완료 테스트용. NAME_FILE_SIZE와 SIGN_FILE_SIZE를 똑같은 크기로 하고 파일을 하나만 보내면 된다.
class EntAppDriverApiDeliveryPackageSignatureReq2:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}/signature2"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
}
class EntAppDriverApiDeliveryPackageSignatureRes2:eEntity()
//--- 인수 거절 -----------------------------------------------------------------
class EntAppDriverApiDeliveryPackageDeniedReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageInvoiceRowid}/denied"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageInvoiceRowid}", deliveryPackageInvoiceRowid)
    var deliveryPackageInvoiceRowid:String by string(::deliveryPackageInvoiceRowid)
    var sensorCode:String by string(::sensorCode)
}
class EntAppDriverApiDeliveryPackageDeniedRes:eEntity()
//--- 수령사 주소 변경 -----------------------------------------------------------------
//delivery의 정보를 변경하는게 아니고 따로 기록해야함
class EntAppDriverApiDeliveryPackageReceiverWpReq:EntAppDriverApiReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/package/{deliveryPackageRowid}/receiver/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryPackageRowid}", deliveryPackageRowid)
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
    var addr:String by string(::addr){ validator(ValiReceiverAddr2) }
}
class EntAppDriverApiDeliveryPackageReceiverWpRes:eEntity(){
    var addr:String by string(::addr){ validator(ValiReceiverAddr) }
}
//--- 내 배송 리스트 -----------------------------------------------------------------
class EntAppDriverApiDeliveryReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone)
}
class EntAppDriverApiDeliveryRes:eEntity(){
    class Delivery:eEntity(){
        var isBoundx: Boolean by bool(::isBoundx){ default(true, false) }
        var deliveryRowid:String by string(::deliveryRowid)   // 배송 일련번호
        var packer: EntAppDriverApiPackerReceiver by entity(::packer, EntAppDriverApiPackerReceiver)
        var receiver: EntAppDriverApiPackerReceiver by entity(::receiver, EntAppDriverApiPackerReceiver)
        var packageCount:String by string(::packageCount) //포장한 패키지 수 :0일때 미포장 상태
        var deliveryCount:String by string(::deliveryCount) //배송완료,인수 거절 된 패키지 수
        var isCancellable: Boolean by bool(::isCancellable) //취소 가능 상태
        var requestDate:String by string(::requestDate)
        var returnPackageCount:String by string(::returnPackageCount){ default("0", false) }
    }
    var deliveryList: MutableList<Delivery> by entityList(::deliveryList, EntAppDriverApiDeliveryRes::Delivery){ default(mutableListOf(),false) }
}
//--- 배송 취소 가능 상태인지 확인 -----------------------------------------------------------------
class EntAppDriverApiDeliveryCancelCheckReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/{deliveryRowid}/cancel/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryRowid}", deliveryRowid)
    var deliveryRowid:String by string(::deliveryRowid)
}
class EntAppDriverApiDeliveryCancelCheckRes:eEntity(){
    var deliveryRowid:String by string(::deliveryRowid)
}
//--- 배송 정보 -----------------------------------------------------------------
class EntAppDriverApiDeliveryVReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/{deliveryRowid}/v"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryRowid}", deliveryRowid)
    var deliveryRowid:String by string(::deliveryRowid)
    var isDetail: Boolean by bool(::isDetail){ default(true, false) } //리스폰스 상세 정보를 안받고 서버 벨리만 하고 싶을때 false
}
class EntAppDriverApiDeliveryVRes:eEntity(){
    class Detail:eEntity(){
        var deliveryRowid:String by string(::deliveryRowid)   // 배송 일련번호
        var deliveryId:String by string(::deliveryId)   // 배송 번호
        var requestDate:String by string(::requestDate)   // 배송 출발 요청일
        var packer: EntAppDriverApiPackerReceiver.Greenie by entity(::packer, EntAppDriverApiPackerReceiver::Greenie) //포장사
        var receiver: EntAppDriverApiPackerReceiver.Greenie by entity(::receiver, EntAppDriverApiPackerReceiver::Greenie) //수령사
        class DeliveryPackage:eEntity(){
            var deliveryPackageRowid:String by string(::deliveryPackageRowid)
            var invoiceNum:String by string(::invoiceNum)
            var packageState: EnumPackageState by enum(::packageState)
        }
        var deliveryPackageList: MutableList<DeliveryPackage> by entityList(::deliveryPackageList, ::DeliveryPackage){ default(mutableListOf(),false) } //패키지 정보
        var isCancellable: Boolean by bool(::isCancellable) //취소 가능 상태
    }
    var detail: Detail by entity(::detail, ::Detail){
        default(Detail().also{ detail ->
            detail.deliveryRowid = ""
            detail.deliveryId = ""
            detail.requestDate = ""
            detail.packer = EntAppDriverApiPackerReceiver.Greenie().also{ company->
                company.companyName = ""
                company.companyAddr = ""
                company.companyTel = ""
            }
            detail.receiver = EntAppDriverApiPackerReceiver.Greenie().also{ company->
                company.companyName = ""
                company.companyAddr = ""
                company.companyTel = ""
            }
            detail.isCancellable = false
        }, false)
    }
}
//--- 배송이 배송완료 상태인지 확인 -----------------------------------------------------------------
class EntAppDriverApiDeliveryCompleteCheckReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/{deliveryRowid}/complete/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryRowid}", deliveryRowid)
    var deliveryRowid:String by string(::deliveryRowid)
}
class EntAppDriverApiDeliveryCompleteCheckRes:eEntity(){
    var deliveryRowid:String by string(::deliveryRowid)
}
//--- 배송 취소 상세 -----------------------------------------------------------------
class EntAppDriverApiDeliveryCancelReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/{deliveryRowid}/cancel"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryRowid}", deliveryRowid)
    var deliveryRowid:String by string(::deliveryRowid)
}
class EntAppDriverApiDeliveryCancelRes:eEntity(){
    var deliveryRowid:String by string(::deliveryRowid)
    var packer: EntAppDriverApiPackerReceiver.Greenie by entity(::packer, EntAppDriverApiPackerReceiver::Greenie)
    var receiver: EntAppDriverApiPackerReceiver.Greenie by entity(::receiver, EntAppDriverApiPackerReceiver::Greenie)
    class DeliveryPackage:eEntity(){
        var invoiceNum:String by string(::invoiceNum)
        var packageStateRowid: Int by int(::packageStateRowid)
    }
    var deliveryPackageList: MutableList<DeliveryPackage> by entityList(::deliveryPackageList, EntAppDriverApiDeliveryCancelRes::DeliveryPackage){ default(mutableListOf(),false) } //패키지 정보
}
//--- 배송 취소 -----------------------------------------------------------------
class EntAppDriverApiDeliveryCancelWpReq:EntAppDriverApiAuthWReq(){
    companion object{
        const val PATH = "$APP_DRIVER_API_PATH/delivery/{deliveryRowid}/cancel/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = (if(isJSON) PATH_JSON else PATH).replace("{phone}", phone).replace("{deliveryRowid}", deliveryRowid)
    var deliveryRowid:String by string(::deliveryRowid)
}
class EntAppDriverApiDeliveryCancelWpRes:eEntity()