import app.ApiDriver
import app.DriverApp
import app.hashManager
import comp.input.CompInputSingle
import ein2b.core.core.Now
import ein2b.core.core.err
import ein2b.core.core.jsNow
import ein2b.core.view.eDomBinder
import ein2b.core.view.eView
import kotlinx.browser.document
import m42.api.M42ApiRsc
import org.w3c.dom.HTMLElement
import shipper.modal.ShipperRequestAddrChangeModalView
import view.CompViewAlert
import view.CompViewBlocking
import view.CompViewConfirm
import view.CompViewToast

lateinit var requestAddrChangeModalEV:eView<HTMLElement>
suspend fun main(){
    Now.instance = jsNow
    eView.binder = eDomBinder().apply{start()}
    ApiDriver
    //View
    val viewEL by lazy{ (document.querySelector("#view") as? HTMLElement) ?: err("invalid #view") }
    requestAddrChangeModalEV = ShipperRequestAddrChangeModalView()
    listOf(requestAddrChangeModalEV).forEach{
        it.setTemplate()
        viewEL.appendChild(it.template!!)
    }
    CompViewBlocking.init(viewEL)
    CompViewAlert.init(viewEL)
    CompViewConfirm.init(viewEL)
    CompViewToast.init(viewEL)

    M42ApiRsc.cat("ln", DriverApp.language() as String)
    M42ApiRsc.rsc("driver_app", "login", "deliveryrequest")
    ApiDriver.logincheck()
    hashManager.init()

    val keyboardPaddingEl = document.querySelector("#keyboardPadding") as? HTMLElement ?: err("invalid #keyboardPadding")
    CompInputSingle.inputFocus = { keyboardPaddingEl.style.display = "block" }
    CompInputSingle.inputBlur = { keyboardPaddingEl.style.display = "none" }
}