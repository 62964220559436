package boundxDriver.common.entity.api.app.driver

import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion
import boundxDriver.common.vali.ValiAppDriverPhone

sealed class EntAppDriverApiAccount:eEntity(){
    companion object:eEntityUnion<EntAppDriverApiAccount>(::Login, ::Logout)
    class Login:EntAppDriverApiAccount(){ //기사 정보
        var isAnonymous: Boolean by bool(::isAnonymous) //미인증 로그인이면 true
        var driverRowid:String by string(::driverRowid)
        var phone:String by string(::phone)
        var userName:String by string(::userName)
        var profileUrl:String by string(::profileUrl)
    }
    class Logout:EntAppDriverApiAccount()
}

abstract class EntAppDriverApiResponse<DATA>:eEntity(true){
    companion object{
        val NO_ERROR = Error()
        val LOGOUT = EntAppDriverApiAccount.Logout()
    }
    class Error(message:String = "", method:String = "alert", title:String = "실패"):eEntity(true){
        var title:String by string(::title){ default(title, false) }
        var message:String by string(::message){ default(message, false) }
        var method:String by string(::method){ default(method, false) }
    }
    var ver:String by string(::ver){ default("1", false) }
    var account: EntAppDriverApiAccount by entity(::account, EntAppDriverApiAccount){ default(LOGOUT, false) }
    var error: Error by entity(::error, ::Error){ default(NO_ERROR) }
    abstract var data:DATA
    val isError get() = error !== NO_ERROR
    val isLogin get() = account is EntAppDriverApiAccount.Login
}
class AppDriverResponse<DATA:eEntity>(val factory:()->DATA):EntAppDriverApiResponse<DATA>(){
    override var data: DATA by entity(::data, factory){
        default(factory(), false)
        include { !isError }
    }
}
abstract class EntAppDriverApiReq:eEntity(){
    abstract fun _url(isJSON:Boolean):String
    var phone:String by string(::phone){ validator(ValiAppDriverPhone) }
    open val rsckeys = ""
}


sealed class EntAppDriverApiPackerReceiver:eEntity(){
    companion object:eEntityUnion<EntAppDriverApiPackerReceiver>(::Greenie, ::Boundx)
    class Greenie:EntAppDriverApiPackerReceiver(){
        var companyName:String by string(::companyName) //회사명
        var companyAddr:String by string(::companyAddr) //회사 주소
        var companyTel:String by string(::companyTel)   //회사 연락처
    }
    class Boundx:EntAppDriverApiPackerReceiver(){
        var name:String by string(::name) //회원이름
        var phone:String by string(::phone) //연락처
        var addr:String by string(::addr) //주소
    }
}
const val APP_DRIVER_PHONE_LOGOUT = "01000000000"
const val APP_DRIVER_PHONE_ANONYMOUS = "01000000001"