package shipper.deliveryList

import Factory
import app.ApiShipper
import app.DriverApp
import app.App
import boundxDriver.common.enm.db.EnumDeliveryState
import boundxDriver.common.entity.api.user.EntUserApiSubShipperDeliveryReq
import boundxDriver.common.entity.api.user.EntUserApiSubShipperDeliveryRes
import comp.CompWebAppHeader
import comp.compPaginationSet
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import prop.compPagination

private val factory = Factory.htmlUrl("shipper/deliveryList/deliveryList")
private val deliveryT = Factory.htmlUrl("shipper/deliveryList/deliveryT")

private enum class K{
    listArea, noListArea, list, page,
    list_deliveryTitle, list_requestDate, list_itemTitle, list_packerAddr, list_packerName, list_receiverAddr, list_receiverName, list_deliveryState;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun DeliveryListView() = eView(factory){ rootView->
    CompWebAppHeader(rootView) {
        it.title = "배송 리스트"
        it.backClick = { DriverApp.back() as Unit }
    }
    App.emptySub(rootView, K.listArea, K.noListArea, K.list)
    rootView.compPaginationSet(K.page, "page"){}
    rootView.addEntityHook(EntUserApiSubShipperDeliveryRes::class, object:eEntityHook<HTMLElement, EntUserApiSubShipperDeliveryRes>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiSubShipperDeliveryRes){
            val noListArea = view.sub(K.noListArea){
                it.html = "배송 리스트가 없습니다."
                it.displayNone()
            }
            val listArea = view.sub(K.listArea){ it.displayNone() }
            if(entity.deliveryList.isEmpty()) noListArea.displayBlock() else listArea.displayBlock()
            App.setCommonList(view.sub(K.list), deliveryT, entity.deliveryList,
                K.list_deliveryTitle, K.list_requestDate, K.list_itemTitle, K.list_packerAddr,
                K.list_packerName, K.list_receiverAddr, K.list_receiverName)
            { _, deliveryView, delivery ->
                deliveryView.sub(K.list_deliveryState){
                    it.html = delivery.deliveryState.title
                    it.className = when(delivery.deliveryState){
                        EnumDeliveryState.NO_PACKAGING-> "state-round-badge state-lightgray"
                        EnumDeliveryState.SHIPPING-> "state-round-badge state-blue"
                        EnumDeliveryState.COMPLETE-> "state-round-badge state-green"
                        else-> "state-round-badge state-red"
                    }
                }
            }
            view.sub(K.page).compPagination{ compPage ->
                compPage.setPage(entity.meta.totalRows.toLong(), entity.meta.page.toLong(), entity.meta.rowPerPage.toLong(), entity.meta.pagePerGroup.toLong())
                compPage.clickEvent = { p ->
                    ApiShipper.subShipperDeliveryList(EntUserApiSubShipperDeliveryReq().also{
                        it.userId = ApiShipper.userId
                        it.page = "$p"
                    })?.also {
                        rootView.entity(it)
                        window.scrollTo(0.0, 0.0)
                    }
                }
            }
        }
    })
}