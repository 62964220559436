package boundxDriver.common.enm.db

//회사 역할
enum class EnumRoleCat(val rowid:Int, val title:String, val imageUrl:String, val imageUrlWhite:String, val imageUrlLnb:String) {
    MASTER(1, "마스터",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/c4883b54f2a5f2901beb891b057ee712.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/ae7e3257ca3727c0ceb97d6e50a0f50b.svg",
        ""
    ), //실제 테이블에는 없음
    SHIPPER(10, "배송의뢰사",
    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/517f5931b9c15d583fbd9163f28227ab.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/7221ff467b2f6b501dc07a891197d8d1.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/514ad31f9c8f68bac8ab846b46ca78a7.svg"
    ),
    PACKER(20, "포장사",
    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/99081419fdb480a2f34975d95c28d913.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/d6289c1499dfd326260b14e16298bee6.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/e6d8e40bf2028fcd2b0d6c31d1b313b5.svg"
    ),
    CARRIER(30, "배송사",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/aa523cbb9b54b95c5cc8b6216632e151.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/50c3815a10291d7676682ef25ba3201f.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/c6133894e9b1a7933852956a0b1b6c22.svg"
    ),
    RECEIVER(40, "수령사",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/d20f7b5a569156fd81ab230f7571c9ad.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/9e1374f6bbb54b9e3a4214e7c978974a.svg",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/4f78a31216a51e2db25f4b7de1ded858.svg"
    )
    ;
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 역할입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 역할입니다.")

    }

    //역할과 관련된 인가리스트
    val authorityList get() = EnumAuthorityCat.values().filter { it.roleCat == this }

    val isForCompany get() = this != MASTER
}