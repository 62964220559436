package comp.input

import Factory
import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement

/* ********************* CompInputSelect 사용법 *********************
                CompInputSelect<Int> {
                    it.wrapperClass = "default1"                                    // 컴포넌트 클래스 설정
                    it.afterInited = {                                                  // 값 초기화
                        it.setList(
                            it.item("체크1", 1, isSelect = true, isDisabled = false),     // 라벨명, 밸류, select 상태, 활성화/비활성화
                            it.item("체크2", 2, isSelect = false, isDisabled = false),
                            it.item("체크3", 3, isSelect = false, isDisabled = true)
                        )
                    }
                    it.vali = it.singleRule("인풋셀렉트 벨리 에러.")                            //벨리데이션 검증(check시 발동)
                    it.checkBlock = {
                            test0A.html = "선택한 값 IDX-> $it"                                 //select 선택시 idx 값이 온다.
                        }
                })
        }
*/
class CompInputSelect<V>:CompInputMulti<V, V>(){
    companion object{
        private const val WRAPPER = "CompInputSelect_wrapper"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<option data-view=""></option>""")
        internal operator fun<V> invoke(block:(CompInputSelect<V>)->Unit):CompInputSelect<V>{
            val comp = CompInputSelect<V>()
            block(comp)
            return comp
        }
    }
    override val subKey:String = WRAPPER
    override val factory:suspend ()-> HTMLElement = Factory.html("""<select data-view="$WRAPPER"></select>""")

    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    it.value = idx
                    it.checked = item.isSelect
                    it.disabled = item.isDisabled
                    it.html = item.label
                }
                if(item.isSelect) value.inputValue(listOf(idx))
            }
        }
        target.change = { e, el ->
            val vIdx = eEvent(e,el).value.toInt()
            list.forEachIndexed{ idx, i -> i.isSelect = vIdx == idx }
            value.inputValue(listOf(vIdx))
            checkBlock?.invoke(vIdx)
        }
    }
    var checkBlock:((idx:Int)->Unit)? = null
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}

/* ***************** CompSelect 사용법 **************
val select1 = rootView.compInputSection("서브키 값", errorClass = "밸리 메시지 클래스 설정"){
        listOf(
            CompSelect<String>{
                it.wrapperClass = "select-list"                         // 셀렉트박스 wrapperClass 변경
                it.placeholder = "국가 선택"                            // 플레이스홀더 설정
                it.afterInited = {
                    it.setList(
                        it.item("한국", "1", false, false),             // it.item("라벨", "value", isSelect, isDisabled)
                        it.item("싱가폴", "2", false, false),
                        it.item("미국", "3", false, false)
                    )
                }
                it.vali = it.singleRule("밸리메시지")                    // 셀렉트 밸리 체크

                it.checkBlock = {                                        // 선택했을때 처리할 로직
                    console.log("CompSelect::", it)
                }
            }
        )
    }

    // 이벤트 리스너와 같이 사용할때는 컴포넌트를 변수로잡고
    select1.check()                                                       // 검사
    select1.values()                                                      // 값 가져오기

    // 셀렉트 컴포넌트는  wrapperClass만 설정할 수 있다 그밑에있는 구조들의 css를 만지고 싶다면 style에 이걸참고해서 조작하면된다

    .select-list{position:relative;width:200px;height:24px}                                                 // 셀렉트 박스, it.wrapperClass와 동일
    .select-list .select{width:100%;height:100%;line-height:24px;border:1px solid #CCC;padding:0 8px}       // 셀렉트 라벨
    .select-list .arrow-up,.select-list .arrow-down{                                                        // 화살표 모양
        position:absolute;top:7px;right:5px;
        width:0;height:0;
        border-bottom:10px solid #00F;
        border-left:8px solid transparent;
        border-right:8px solid transparent
    }
    .select-list .arrow-down{border-top:10px solid #00F;border-bottom:none}
    .select-list .item-list{                                                                                 // 아이템리스트 박스
        display:none;position:absolute;top:24px;left:0;
        min-width:100%;max-height:100px;overflow-y:auto;
        background:#FFF;padding:10px 0;
        border:1px solid #CCC
    }
    .select-list .item-list li{line-height:18px;padding:5px 8px;cursor:pointer}                              // 아이템리스트
    .select-list .item-list li.selected,.select-list .item-list li:hover{background:#F00;color:#FFF}
    .select-list .item-list li.disabled{background:#CCC;cursor:default;color:#666}
*/
class CompSelect<V>:CompInputMulti<V, V>(){
    companion object{
        private val LIST = mutableListOf<CompSelect<*>>()
        private const val CLASS_NAME = "CompSelect"
        private const val SELECT = "${CLASS_NAME}_select"
        private const val ARROW = "${CLASS_NAME}_arrow"
        private const val ITEM_LIST = "${CLASS_NAME}_item_list"
        private const val SELECTED_CLASS = "selected"
        private const val FOCUSED_CLASS = "focused"
        private const val DISABLED_CLASS = "disabled"
        private const val ERROR_CLASS = "error"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="" class="item-item"></li>""")
        operator fun<V> invoke(block:(CompSelect<V>)->Unit):CompSelect<V>{
            val comp = CompSelect<V>()
            LIST.add(comp)
            block(comp)
            comp.afterTargetInited = {
                comp.target.sub(SELECT).html = comp.placeholder
                comp.target.sub(ARROW)
                comp.target.sub(ITEM_LIST).className = if(comp.isReverse) "item-list-reverse" else "item-list"
                comp.itemListDisplay()
                comp.target.click = {e,_->
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    if(!comp.isDisabled){
                        eLaunch{
                            if(!comp.isItemListOpen) LIST.forEach{ it.itemListDisplay(false) }
                            comp.itemListDisplay(!comp.isItemListOpen)
                        }
                    }
                }
                eWindow.addClick{ eLaunch{ comp.itemListDisplay(false) } }
            }
            return comp
        }
    }

    private var isItemListOpen = false
    suspend fun itemListDisplay(isOpen:Boolean = false){
        isItemListOpen = isOpen
        target.sub(ITEM_LIST){ if(isItemListOpen) it.displayBlock() else it.displayNone() }
        target.className = setClassName()
    }
    override val subKey:String = "${CLASS_NAME}_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""
    <div data-view="$subKey">
        <div data-view="$subKey.$SELECT" class="selectbox flex-grow-1 ellipsis"></div><div data-view="$subKey.$ARROW" class="arrow"></div>
        <ul data-view="$subKey.$ITEM_LIST" class="item-list"></ul>
    </div>""")
    public override suspend fun setList(vararg list:Item<V>){
        isOk = true
        itemList = list
        value.inputValue(listOf())
        isDisabled = list.all{ it.isDisabled }
        target.className = setClassName()
        target.sub(ITEM_LIST).setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    item.view = it
                    item.idx = idx
                    it.html = item.label
                    it.className = setClassName(item)
                    it.click = { e, _ ->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        if(!item.isDisabled) eLaunch{
                            isOk = true
                            list.forEach{ listItem->
                                listItem.isSelect = false
                                listItem.view?.className = setClassName(listItem)
                            }
                            item.isSelect = true
                            it.className = setClassName(item)
                            itemListDisplay()

                            value.inputValue(listOf(item.idx))
                            checkBlock?.invoke(item.idx)
                        }
                    }
                }
                if(item.isSelect){
                    defaultIdx = item.idx
                    value.inputValue(listOf(item.idx))
                    initBlock?.invoke(item.idx)
                }
            }
        }
        if(list.all { !it.isSelect }) target.sub(SELECT).html = placeholder
    }
    suspend fun setDefault() {
        itemList.forEach{ listItem->
            listItem.isSelect = defaultIdx == listItem.idx
            listItem.view?.className = setClassName(listItem)
        }
        value.inputValue(if(defaultIdx == -1) listOf() else listOf(defaultIdx))
        if(itemList.all { !it.isSelect }) target.sub(SELECT).html = placeholder
    }
    private fun setClassName():String = "$wrapperClass${if(isDisabled) " $DISABLED_CLASS" else if(isItemListOpen) " $FOCUSED_CLASS" else if(isOk) "" else " $ERROR_CLASS"}"
    private fun setClassName(item:Item<V>):String{
        if(item.isSelect) eLaunch{ target.sub(SELECT).html = item.label }
        return "item-item${if(item.isDisabled) " $DISABLED_CLASS" else if(item.isSelect) " $SELECTED_CLASS" else ""}"
    }
    override suspend fun error(isOK:Boolean){
        isOk = isOK
        target.className = setClassName()
    }
    private var isOk = true
    private var defaultIdx = -1
    override var placeholder = ""
    var isDisabled = false
    var checkBlock:((idx:Int)->Unit)? = null
    var initBlock:((idx:Int)->Unit)? = null
    var isReverse = false
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}