package driver.edit

import app.ApiDriver
import boundxDriver.common.entity.api.app.driver.web.EntAppDriverWebApiDriverEReq
import domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class DriverEditP(router:eRouter<HTMLElement>): PageBase(::DriverEdit, router){
    override val isBodyBgGray = false
    override suspend fun net(data:eRouterData){
        ApiDriver.driverE(EntAppDriverWebApiDriverEReq().also{
            it.phone = ApiDriver.member.phone
            it.driverRowid = ApiDriver.member.driverRowid
        })?.also{
            view?.entity(it)
        }
    }
}